import React from 'react';
import { Avatar, Box, Typography, useTheme } from '@mui/material';
import { formatAuthorName } from '../../utils/common-utils';

const stringAvatar = (
  firstName: string,
  lastName: string,
  avatarStyles: React.CSSProperties,
) => {
  const { palette } = useTheme();
  return {
    sx: {
      bgcolor: palette.keyops.blue.lightSteelBlue,
      color: palette.keyops.white.main,
      textTransform: 'uppercase',
      height: 52,
      width: 52,
      ...avatarStyles,
    },
    children: formatAuthorName([firstName, lastName], true),
  };
};

type KeyOpsUserDisplayProperties = {
  firstName: string;
  lastName?: string;
  specialty?: string;
  avatarStyles?: React.CSSProperties;
  primaryTextStyles?: React.CSSProperties;
};

export const KeyOpsUserDisplay = ({
  firstName,
  lastName = '',
  specialty,
  avatarStyles,
  primaryTextStyles,
}: KeyOpsUserDisplayProperties) => {
  const { palette } = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: 2,
      }}
    >
      <Avatar {...stringAvatar(firstName, lastName, avatarStyles)} />
      <Box>
        <Typography
          variant="body2"
          sx={{ color: palette.keyops.black.main, ...primaryTextStyles }}
        >
          {`${firstName} ${lastName}`}
        </Typography>
        {specialty && (
          <Typography sx={{ color: palette.keyops.gray[500] }}>
            {specialty}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
