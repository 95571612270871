import React, { ReactNode } from 'react';
import { alpha } from '@mui/material';

import { theme } from '../theme';
import { MessageDto } from '@keyops-hcp/dtos';

export function fetchUrlHashData() {
  // Fetch gd & msgId from the URL hash
  const hash = globalThis.location.hash.slice(1); // Remove the '#' from the hash
  let gd; // General Discussion
  let messageId;

  if (hash) {
    // Check if the hash starts with 'gd.'
    if (hash.startsWith('gd.')) {
      gd = true;
      messageId = hash.slice(3); // Extract the part after 'gd.'
    } else {
      messageId = hash; // Handle #msgId format
    }
  }

  return { gd, msgId: messageId };
}

export function scrollToMessageId(messageId: string) {
  const element = document.querySelector(`#${CSS.escape(messageId)}`);
  // Scroll to the element if it's present
  if (element) {
    setTimeout(() => {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      if (globalThis.history.replaceState) {
        globalThis.history.replaceState(
          undefined,
          undefined,
          globalThis.location.pathname,
        );
      }
    }, 750);
  }
}

/**
 * Helper function to extract userIds from a message with Tagify mentions
 * @param message - the message with userIds
 * @example  ​[[{\"id\":\"1000111\",\"value\":\"User A\",\"prefix\":\"@\"}]] and [[{\"id\":\"1000112\",\"value\":\"User B\",\"prefix\":\"@\"}]] ok"
 * @returns string[] - the array of userIds (as string, not number)
 */
export function extractMentionIds(message: string): string[] {
  // Match all occurrences of the tagged mentions
  const mentionRegex = /\[\[{"id":"(\d+)","value":".*?","prefix":"@"}\]\]/g;

  // Array to store the extracted IDs
  const ids: string[] = [];

  // Extract all matches using regex
  let match;
  while ((match = mentionRegex.exec(message)) !== null) {
    ids.push(match[1]); // Push the captured `id` group into the array
  }

  return ids;
}

/**
 *
 * @param {string} input - If mentions, looks like:
 *  `Hello [[{"id":"1","value":"${value1}","prefix":"@"}]]! meet [[{"id":"2","value":"${value2}","prefix":"@"}]].`;
 *
 * @param {string} currentUserId - The id of the connected user
 * @returns {ReactNode[]} - Mentions and free text rendered as HTML elements
 */
export const parseAndHighlightMentions = (
  input: string,
  currentUserId?: string,
): ReactNode[] => {
  // Updated regex to capture both `id` and `value`
  const mentionedIdAndValueRegex =
    /\[\[{"id":"(\d+)","value":"([^"]+)","prefix":"@"}]]/g;

  const elements: React.ReactNode[] = [];
  let lastIndex = 0; // To track the position in the string where the last match ended, to keep the plain text between the current match and the previous one.

  let match;
  while ((match = mentionedIdAndValueRegex.exec(input)) !== null) {
    const id = match[1]; // Capture the id
    const value = match[2]; // Capture the value

    // Start index of this match
    const startIndex = match.index;

    // If there’s any text before the current match, it's pushed into the elements array.
    // This ensures that the text between matches is preserved.
    if (lastIndex < startIndex) {
      elements.push(input.slice(lastIndex, startIndex));
    }

    const isCurrentUser = id === currentUserId;

    // Push the mention span
    elements.push(
      <span
        key={value}
        style={{
          color: theme.palette.keyops.blue.midnightBlue,
          backgroundColor: isCurrentUser
            ? alpha(theme.palette.keyops.teal, 0.2)
            : theme.palette.keyops.blue.arcticBlue,
        }}
      >
        {value}
      </span>,
    );

    // Ensures == the next match will continue from the correct position in the string.
    lastIndex = mentionedIdAndValueRegex.lastIndex;
  }

  // Push remaining plain text after the last match
  if (lastIndex < input?.length) {
    elements.push(input.slice(Math.max(0, lastIndex)));
  }

  return elements;
};

/**
 * Count the number of messages depending of the role
 * If participant, filter out hidden messages
 * @param {MessageDto[]} messages
 * @param {'admin'|'moderator'|'participant'} role
 * @returns {number} - The total number of unhidden messages
 */
export const countMessages = (messages: MessageDto[], role: string) => {
  if (role === 'admin' || role === 'moderator') return messages?.length;
  return messages?.filter((message) => !message.hidden)?.length;
};

/**
 * getMessagesToDisplay returns the messages to display by filtering hidden ones if attendee is a participant
 * @param {MessageDto[]} messages
 * @param {'admin'|'moderator'|'participant'} role
 *
 * @returns {MessageDto[]} - The messages to display depending on the role
 */
export const getMessagesToDisplay = (
  messages: MessageDto[],
  role: string,
): MessageDto[] => {
  if (role === 'admin' || role === 'moderator') return messages;
  return messages?.filter((message) => !message.hidden);
};
