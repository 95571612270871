import React from 'react';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import {
  Card,
  CardContent,
  Container,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { AdBoardSectionType } from '@keyops-hcp/constants';

interface SectionOption {
  label: string;
  description: string;
  value: AdBoardSectionType;
  type: AdBoardSectionType;
}

interface AddSectionProperties {
  onSaveSection: (section: Partial<SectionOption>) => void;
  onCloseAddSection: () => void;
}

interface SectionOptionComponentProperties {
  option: SectionOption;
  onSaveSection: (section: Partial<SectionOption>) => void;
}

const sectionTypeOptions: SectionOption[] = [
  {
    label: 'Video',
    description: `Participants watch a video and must post a configurable number of required comments. They can also view and reply to each other's comments.`,
    value: 'video',
    type: 'video',
  },
  {
    label: 'Documents',
    description:
      'Participants read the attached documents. Supports DOC, PDF, PPT, XLS, and ZIP files.',
    value: 'attachment',
    type: 'attachment',
  },
  {
    label: 'Survey',
    description: `Participants complete a survey. Their survey responses are private (i.e. they can't see each other's answers).`,
    value: 'survey',
    type: 'survey',
  },
  {
    label: 'Discussion board',
    description:
      'Participants share their thoughts on a specific topic. Each participant must post at least one comment per topic.',
    value: 'discussion',
    type: 'discussion',
  },
  {
    label: 'Document mark up',
    description: `Participants read the attached document and must post a configurable number of required comments. They can also view and reply to each other's comments.`,
    value: 'documentMarkup',
    type: 'documentMarkup',
  },
];

const SectionOptionComponent: React.FC<SectionOptionComponentProperties> = ({
  option,
  onSaveSection,
}) => (
  <Card
    key={option.value}
    sx={{ cursor: 'pointer' }}
    onClick={() => onSaveSection({ type: option.type })}
  >
    <CardContent>
      <Typography fontWeight={500} gutterBottom>
        {option.label}
      </Typography>
      <Typography variant="body2" gutterBottom>
        {option.description}
      </Typography>
    </CardContent>
  </Card>
);

const AddSection: React.FC<AddSectionProperties> = ({
  onSaveSection,
  onCloseAddSection,
}) => (
  <>
    <Link
      component="button"
      variant="body2"
      underline="none"
      sx={{ display: 'flex', alignItems: 'center' }}
      onClick={onCloseAddSection}
    >
      <ArrowBackIosNewRoundedIcon sx={{ fontSize: 16, mr: 1 }} />
      Back to content
    </Link>
    <Container maxWidth="sm" sx={{ mt: 2 }}>
      <Typography fontWeight={500} gutterBottom>
        Select an activity type
      </Typography>
      <Stack spacing={2} mt={2}>
        {sectionTypeOptions.map((option, index) => (
          <SectionOptionComponent
            key={`${option.type}-${index}`}
            option={option}
            onSaveSection={onSaveSection}
          />
        ))}
      </Stack>
    </Container>
  </>
);

export default AddSection;
