import React from 'react';
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material';
import i18next from '../../languages/i18n.config';
import { useAdBoardContext } from '../../context';
import { ModeratorChip } from '../ModeratorChip';
import { hoverItemStyle } from '../../styles';
import { PrimaryTooltip } from '../PrimaryTooltip';
import { User } from '../../icons';
import {
  filterModerators,
  filterParticipants,
} from '../../utils/kolab/attendees.utils';
import { KeyOpsUserDisplay } from '../KeyOpsUserDisplay';
interface AttendeesProperties {
  sx?: SxProps;
}

export const Attendees: React.FC<AttendeesProperties> = ({ sx }) => {
  const { palette } = useTheme();
  const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(
    null,
  );
  const { allParticipantsData, isLoading, isError } = useAdBoardContext();

  if (isLoading) return <div>{i18next.t('common.loading')}</div>;
  if (isError) return <div>{i18next.t('common.error')}</div>;

  const participants = filterParticipants(allParticipantsData);
  const moderators = filterModerators(allParticipantsData);

  const open = Boolean(anchorElement);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElement(null);
  };
  return (
    <Box sx={sx}>
      <PrimaryTooltip title={i18next.t('navbar.hover.attendees')}>
        <IconButton
          id="attendees-button"
          data-testid="attendees-icon-button"
          aria-controls={open ? 'attendees-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          sx={{
            color: palette.keyops.blue.midnightBlue,
            px: 0.5,
            ...hoverItemStyle(),
          }}
          disableFocusRipple
          disableTouchRipple
          disableRipple
          onClick={handleClick}
        >
          <Typography
            variant={'body3'}
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              color: palette.keyops.text.primary,
              mr: 0.5,
            }}
          >
            {allParticipantsData?.length}
          </Typography>
          <User
            sx={{
              fontSize: '25px',
            }}
          />
        </IconButton>
      </PrimaryTooltip>
      <Menu
        id="attendees-menu"
        data-testid="attendees-menu"
        aria-labelledby="attendees-button"
        anchorEl={anchorElement}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {participants?.map((participant, index) => (
          <MenuItem key={index} sx={{ minWidth: 240 }}>
            <KeyOpsUserDisplay
              firstName={participant?.user?.firstName}
              lastName={participant?.user?.lastName}
              avatarStyles={{
                width: 30,
                height: 30,
                backgroundColor:
                  participant.role === 'moderator'
                    ? palette.keyops.blue.midnightBlue
                    : palette.keyops.gray[400],
                color: palette.keyops.white.main,
                fontSize: 12,
                fontWeight: 500,
              }}
              primaryTextStyles={{ fontSize: '0.875rem' }}
            />
          </MenuItem>
        ))}
        {moderators?.map((moderator, index) => (
          <MenuItem key={index} sx={{ minWidth: 240 }}>
            <Stack direction={'row'} alignItems={'center'} gap={1.5}>
              <KeyOpsUserDisplay
                firstName={moderator?.user?.firstName}
                lastName={moderator?.user?.lastName}
                avatarStyles={{
                  width: 30,
                  height: 30,
                  backgroundColor: palette.keyops.gray[400],
                  color: palette.keyops.white.main,
                  fontSize: 12,
                  fontWeight: 500,
                }}
                primaryTextStyles={{ fontSize: '0.875rem' }}
              />
              <ModeratorChip />
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
