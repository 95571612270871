/* eslint-disable unicorn/filename-case */
import {
  highlight,
  highlight2,
  hoverHighlight,
  hoverHighlight2,
} from '@keyops-hcp/constants';

// Function to highlight a single text node within one element
const highlightSingleElement = (range: Range, messageId: string) => {
  const span = document.createElement('span');
  const clonedContents = range.cloneContents();

  // Determine the base class
  const parentElement = range.startContainer.parentElement;
  const baseClass =
    parentElement?.classList.contains(highlight) ||
    parentElement?.classList.contains(highlight2)
      ? highlight2
      : highlight;

  // Concatenate existing IDs from the parent
  const parentIds = [...(parentElement?.classList || [])]
    .filter((cls) => cls.startsWith('message-id-')) // Filter by prefix
    .join(' ');

  // Assign the new class with concatenated IDs
  span.className =
    `${baseClass} ${parentIds || ''} message-id-${messageId}`.trim();

  span.append(clonedContents);
  range.deleteContents();
  range.insertNode(span);
};

// Function to highlight text across multiple elements
const highlightMultiElement = (range: Range, messageId: string) => {
  const commonAncestor = range.commonAncestorContainer;
  const walker = document.createTreeWalker(
    commonAncestor,
    NodeFilter.SHOW_TEXT,
    {
      acceptNode: (node) => {
        return range.intersectsNode(node)
          ? NodeFilter.FILTER_ACCEPT
          : NodeFilter.FILTER_REJECT;
      },
    },
  );

  const fragments: { node: Node; startOffset: number; endOffset: number }[] =
    [];

  let currentNode: Node | null = walker.nextNode();
  while (currentNode) {
    const startOffset =
      currentNode === range.startContainer ? range.startOffset : 0;
    const endOffset =
      currentNode === range.endContainer
        ? range.endOffset
        : currentNode.nodeValue?.length || 0;

    // Skip empty or invalid nodes
    if (!currentNode.nodeValue?.trim() || startOffset >= endOffset) {
      currentNode = walker.nextNode();
      continue;
    }

    fragments.push({ node: currentNode, startOffset, endOffset });
    currentNode = walker.nextNode();
  }

  for (const { node, startOffset, endOffset } of fragments) {
    // Check if the parent is already a highlight span
    const parentElement = node.parentNode as HTMLElement;
    const baseClass =
      parentElement?.classList.contains(highlight) ||
      parentElement?.classList.contains(highlight2)
        ? highlight2
        : highlight;

    // Concatenate existing IDs from the parent
    const parentIds = [...(parentElement?.classList || [])]
      .filter((cls) => cls.startsWith('message-id-')) // Filter by prefix
      .join(' ');

    const span = document.createElement('span');
    span.className =
      `${baseClass} ${parentIds || ''} message-id-${messageId}`.trim();

    // Extract text to highlight
    const text = (node.nodeValue || '').slice(startOffset, endOffset).trim();
    if (!text) {
      console.warn('Skipping empty text for node:', node);
      continue;
    }

    span.textContent = text;

    const textBefore = document.createTextNode(
      (node.nodeValue || '').slice(0, Math.max(0, startOffset)),
    );
    const textAfter = document.createTextNode(
      (node.nodeValue || '').slice(Math.max(0, endOffset)),
    );

    // Ensure proper placement of nodes
    if (node.parentNode && 'before' in node && 'remove' in node) {
      (node as ChildNode).before(textBefore);
      (node as ChildNode).before(span);
      (node as ChildNode).before(textAfter);
      (node as ChildNode).remove();
    }
  }
};

// Function to highlight the selected range
export const highlightRange = (range: Range, messageId: string) => {
  const startContainer = range.startContainer;
  const endContainer = range.endContainer;

  if (startContainer === endContainer) {
    highlightSingleElement(range, messageId);
  } else {
    highlightMultiElement(range, messageId);
  }
};

// Function to calculate text index from a node and offset
export const calculateIndex = (
  node: Node,
  offset: number,
  contentReference: HTMLDivElement | null,
): number => {
  if (!contentReference) return; // Early return if the reference is null
  let currentIndex = 0;

  // Traverse all the nodes to calculate the offset
  const walker = document.createTreeWalker(
    contentReference,
    NodeFilter.SHOW_ALL,
  );
  let currentNode: Node | null = walker.nextNode();

  while (currentNode) {
    if (currentNode === node) {
      return currentIndex + offset;
    }
    currentIndex += currentNode.nodeValue?.length || 0;
    currentNode = walker.nextNode();
  }

  return currentIndex;
};

// Function to highlight text based on stored indexes
export const highlightFromIndexes = (
  ranges: { startIndex: number; endIndex: number; messageId: string }[],
  contentReference: HTMLDivElement | null,
) => {
  if (!contentReference) return; // Early return if the reference is null
  const range = document.createRange();

  for (const { startIndex, endIndex, messageId } of ranges) {
    calculateAndHighlightRange({
      startIndex,
      endIndex,
      messageId,
      contentReference,
      range,
    });
  }
};

const calculateAndHighlightRange = ({
  startIndex,
  endIndex,
  messageId,
  contentReference,
  range,
}: {
  startIndex: number;
  endIndex: number;
  messageId: string;
  contentReference: HTMLDivElement;
  range: Range;
}) => {
  let currentIndex = 0;

  const walker = document.createTreeWalker(
    contentReference,
    NodeFilter.SHOW_TEXT,
  );
  let startNode: Node | undefined;
  let endNode: Node | undefined;
  let startOffset = 0;
  let endOffset = 0;

  let currentNode = walker.nextNode();

  while (currentNode && (!startNode || !endNode)) {
    const nodeLength = currentNode.nodeValue?.length || 0;

    // Find start node
    if (!startNode && currentIndex + nodeLength > startIndex) {
      startNode = currentNode;
      startOffset = startIndex - currentIndex;
    }

    // Find end node
    if (!endNode && currentIndex + nodeLength >= endIndex) {
      endNode = currentNode;
      endOffset = endIndex - currentIndex;
    }

    currentIndex += nodeLength;
    currentNode = walker.nextNode();
  }

  if (startNode && endNode) {
    range.setStart(startNode, startOffset);
    range.setEnd(endNode, endOffset);
    highlightRange(range, messageId);
  }
};

// Hover out event
export const handleMouseOver = (event: MouseEvent) => {
  const target = event.target as HTMLElement;

  if (target.tagName === 'SPAN') {
    const classList = [...target.classList];

    // Extract all message-id-* classes
    const messageIdClasses = classList.filter((cls) =>
      cls.startsWith('message-id-'),
    );

    if (messageIdClasses.length > 0) {
      // Find all spans with matching message-id-* classes
      const matchingSpans = document.querySelectorAll(
        messageIdClasses.map((id) => `.${id}`).join(','),
      );

      for (const span of matchingSpans) {
        if (span.classList.contains(highlight)) {
          span.classList.add(hoverHighlight);
        } else if (span.classList.contains(highlight2)) {
          span.classList.add(hoverHighlight2);
        }
      }
    }
  }
};

// Hover in event
export const handleMouseOut = (event: MouseEvent) => {
  const target = event.target as HTMLElement;

  if (target.tagName === 'SPAN') {
    const classList = [...target.classList];

    // Extract all message-id-* classes
    const messageIdClasses = classList.filter((cls) =>
      cls.startsWith('message-id-'),
    );

    if (messageIdClasses.length > 0) {
      // Find all spans with matching message-id-* classes
      const matchingSpans = document.querySelectorAll(
        messageIdClasses.map((id) => `.${id}`).join(','),
      );

      for (const span of matchingSpans) {
        span.classList.remove(hoverHighlight, hoverHighlight2);
      }
    }
  }
};

// Span click event
export const handleSpanClick = (
  event: MouseEvent,
  setSelectedMarkupMessageId: React.Dispatch<React.SetStateAction<string>>,
) => {
  const target = event.target as HTMLElement;

  if (target.tagName === 'SPAN') {
    const classList = [...target.classList];

    // Extract all message-id-* classes
    const messageIdClasses = classList.filter((cls) =>
      cls.startsWith('message-id-'),
    );

    if (messageIdClasses.length > 0) {
      // Get the last message-id-* class
      const lastMessageIdClass = messageIdClasses.at(-1);

      // Extract the messageId from the class (remove the 'message-id-' prefix)
      const messageId = lastMessageIdClass.replace('message-id-', '');

      // Set the selected message ID
      setSelectedMarkupMessageId(messageId);
    }
  }
};

export const getElementRect = (selector: string) =>
  document.querySelector(selector)?.getBoundingClientRect();
