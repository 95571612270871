import { useMutation } from '@tanstack/react-query';

import { queryClient } from '../wrappers';
import { analytics } from '../utils/analytics';
import { updateOneModerationFlags } from '../api/moderation-flags';
import { ModerationFlagsDto } from '@keyops-hcp/dtos';

interface BaseMessageParameters {
  id: string;
  dataToUpdate: Partial<ModerationFlagsDto>;
}

/**
 * @param {string} adBoardId - The id of the current Ad Board
 * @param {string} currentSectionId - The id of the current section
 * @returns
 */
export const useUpdateOneModerationFlags = () => {
  return useMutation<
    { id: string; updatedModerationFlags: ModerationFlagsDto[] },
    unknown,
    BaseMessageParameters
  >(
    async ({
      id,
      dataToUpdate,
    }): Promise<{
      id: string;
      updatedModerationFlags: ModerationFlagsDto[];
    }> => {
      const updatedModerationFlags = await updateOneModerationFlags(
        id,
        dataToUpdate,
      );
      return { id, updatedModerationFlags };
    },
    {
      onSuccess: async (result, _parameters) => {
        analytics.track(`ModerationFlags updated`, {
          id: result.id,
          updatedModerationFlags: result.updatedModerationFlags,
        });

        // Refetch the discussions query
        await queryClient.invalidateQueries({
          queryKey: [
            'getAdBoardModerationFlags',
            result.updatedModerationFlags[0].adBoardId,
          ],
        });
      },
      onError: (error) => {
        console.error(error);
      },
    },
  );
};
