export const ModerationFlagsStatuses = [
  'new',
  'completed',
  'dismissed',
] as const;

export type ModerationFlagsStatus = (typeof ModerationFlagsStatuses)[number];

export const ModerationFlagsTypes = ['urgent', 'recommendation'] as const;

export type ModerationFlagsType = (typeof ModerationFlagsTypes)[number];
