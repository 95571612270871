import React from 'react';
import {
  Box,
  Drawer,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { RightArrow, TaskList } from '../../icons';
import { KeyopsHeader2, KeyopsSubTitle1 } from '../Headers';
import i18next from '../../languages/i18n.config';
import { useAdBoardContext } from '../../context';
import { Loader } from '../Loader';
import { ModerationActionCenterTable } from '../ModerationActionCenterTable';

interface ActionCenterDrawerProperties {
  actionCenterDrawerOpen: boolean;
  toggleActionCenterDrawer: (newOpen: boolean) => void;
}

export const ActionCenterDrawer: React.FC<ActionCenterDrawerProperties> = ({
  actionCenterDrawerOpen,
  toggleActionCenterDrawer,
}) => {
  const { palette, breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const { isLoading, isError, role, adBoardData } = useAdBoardContext();

  return (
    <Drawer
      anchor="right"
      open={actionCenterDrawerOpen}
      onClose={() => toggleActionCenterDrawer(false)}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'transparent',
          },
        },
      }}
      sx={{
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.06)',
        display: role !== 'moderator' && 'none',
      }}
    >
      <Toolbar>
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          <RightArrow
            data-testid="action-center-drawer-close-button"
            sx={{
              fontSize: '24px',
              color: palette.keyops.blue.midnightBlue,
              cursor: 'pointer',
            }}
            onClick={() => toggleActionCenterDrawer(false)}
          />
          <TaskList
            sx={{
              fontSize: '23px',
            }}
          />
          <KeyopsHeader2>
            {i18next.t('ad_board.action_center_drawer.title')}
          </KeyopsHeader2>
        </Stack>
      </Toolbar>
      <Box px={3} sx={isMobile && { maxWidth: '60vw' }}>
        <Typography fontSize={14} lineHeight={'22px'}>
          {i18next.t('ad_board.action_center_drawer.sub_title')}
        </Typography>
        {isLoading && <Loader />}
        {isError && (
          <KeyopsSubTitle1>
            {i18next.t('common.something_wrong')}
          </KeyopsSubTitle1>
        )}{' '}
        {!isLoading && !isError && (
          <Box mt={4}>
            <ModerationActionCenterTable
              adBoard={adBoardData}
              toggleActionCenterDrawer={toggleActionCenterDrawer}
            />
          </Box>
        )}
      </Box>
    </Drawer>
  );
};
