import React, { useEffect, useMemo, useState } from 'react';
import { AppBar, Box, Button, Stack, Toolbar, useTheme } from '@mui/material';
import { SectionDto } from '@keyops-hcp/dtos';
import { useParams } from 'react-router-dom';
import { FiCheckCircle } from 'react-icons/fi';

import { useAdBoardContext } from '../../context';
import {
  getCurrentSection,
  getNextSectionType,
} from '../../utils/adboard-sections.utils';
import { RightArrow } from '../../icons';
import i18next from '../../languages/i18n.config';
import { Loader } from '../Loader';
import { KeyopsHeader2 } from '../Headers';
import { PrimaryTooltip } from '../PrimaryTooltip';

export const AdBoardFinishedComponent = ({ endDate }: { endDate: string }) => {
  const { palette } = useTheme();
  const endDateObject = endDate ? new Date(endDate) : undefined;

  return (
    <Stack justifyContent="center" alignItems="center">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <FiCheckCircle
          data-testid="fi-check-circle"
          color={palette.keyops.teal}
          fontSize="18px"
        />
        <KeyopsHeader2 fontWeight={400}>
          {i18next.t('ad_board.finished_ad_board_card.completed')}
        </KeyopsHeader2>
      </Box>
      <KeyopsHeader2 fontWeight={400} textAlign="center">
        {i18next.t('ad_board.finished_ad_board_card.thanks_for_participating', {
          endDate: endDateObject
            ? ` on  ${endDateObject.toLocaleString('en-US', {
                month: 'long',
                day: 'numeric',
              })}`
            : '',
        })}
      </KeyopsHeader2>
    </Stack>
  );
};

interface AdBoardCompletedTaskBottomBarProperties {
  setCurrentSectionId: (
    id: string,
    contentId?: string,
    gd?: boolean,
    messageId?: string,
  ) => void;
}

export const AdBoardCompletedTaskBottomBar = ({
  setCurrentSectionId,
}: AdBoardCompletedTaskBottomBarProperties) => {
  const { palette } = useTheme();
  const { sectionId } = useParams();

  const {
    isLoading,
    isError,
    adBoardData,
    sectionGroupsData: sectionGroups,
    sectionsData: sections,
    adBoardProgress,
    isSectionFinished, // Provide default values to avoid errors
  } = useAdBoardContext();

  const [currentSection, setCurrentSection] = useState<SectionDto | undefined>(
    undefined,
  );

  useEffect(() => {
    const section = getCurrentSection(sectionId, sections, adBoardProgress);
    setCurrentSection(section);
  }, [sectionId, sections]);

  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);

  useEffect(() => {
    if (sections && sections.length > 0 && currentSection) {
      setCurrentSectionIndex(
        sections.findIndex((section) => section.id === currentSection.id),
      );
    }
  }, [sections, currentSection]);

  const NextTaskButtonComponent = useMemo(() => {
    if (currentSection)
      return (
        <Button
          disabled={!isSectionFinished(currentSection)}
          onClick={() => {
            const nextSection = sections[currentSectionIndex + 1];
            setCurrentSectionId(nextSection.id);
          }}
          variant={isSectionFinished(currentSection) ? 'contained' : 'outlined'}
          endIcon={<RightArrow />}
          sx={{
            minHeight: '40px',
            px: 4,
            borderRadius: '6px',
            color: palette.keyops.white.main,
            backgroundColor: isSectionFinished(currentSection)
              ? palette.keyops.blue.keyopsBlue
              : 'transparent',
            '&:hover': {
              backgroundColor: palette.keyops.blue.midnightBlue,
            },
            '&:disabled': {
              color: palette.keyops.gray[600],
              opacity: '70%',
            },
          }}
          disableRipple
          disableElevation
        >
          {getNextSectionType(sectionGroups, currentSection.id) === 'section'
            ? i18next.t('ad_board.completed_task_button.next_section')
            : i18next.t('ad_board.completed_task_button.next_activity')}
        </Button>
      );

    return <></>;
  }, [
    setCurrentSectionId,
    isSectionFinished,
    getNextSectionType,
    sectionGroups,
    currentSection,
  ]);

  if (isLoading || !currentSection) return <Loader />;
  if (isError) return <div>{i18next.t('common.error')}</div>;

  return (
    <AppBar
      data-testid="AdBoardCompletedTaskBottomBar"
      position="fixed"
      color={'inherit'}
      sx={{
        boxShadow: 'none',
        top: 'auto',
        bottom: 0,
        borderTop: `1px solid ${palette.keyops.gray[200]}`,
      }}
    >
      <Toolbar sx={{ justifyContent: 'center' }}>
        {currentSectionIndex === sections.length - 1 ? (
          <AdBoardFinishedComponent endDate={adBoardData?.endDate} />
        ) : (
          <>
            {isSectionFinished(currentSection) ? (
              NextTaskButtonComponent
            ) : (
              <PrimaryTooltip
                placement="top"
                title={i18next.t(
                  'ad_board.completed_task_button.complete_activity_tooltip',
                )}
              >
                <span>{NextTaskButtonComponent}</span>
              </PrimaryTooltip>
            )}
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};
