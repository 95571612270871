import React from 'react';
import { IconButton, Tooltip, Link, Box, useTheme } from '@mui/material';

import { MessageDto } from '@keyops-hcp/dtos';
import i18next from '../../languages/i18n.config';
import { useAdBoardContext } from '../../context';
import { KeyopsSubTitle2 } from '../Headers';
import { useToggleDiscussionMessageVisibility } from '../../hooks/use-toggle-discussion-message-visibility';
import { analytics } from '../../utils/analytics';
import { Hide } from '../../icons/nova/Hide';
import { Show } from '../../icons/nova/Show';
import { PrimaryTooltip } from '../PrimaryTooltip';

interface ModeratorDiscussionMessageCTAProperties {
  message: MessageDto;
  collapsedView?: boolean;
}

export const ModeratorDiscussionMessageCTA = ({
  message,
  collapsedView = false,
}: ModeratorDiscussionMessageCTAProperties): React.JSX.Element => {
  const { palette } = useTheme();
  const { role } = useAdBoardContext();

  // Hide message feature
  const { mutate: toggleMessageVisibility } =
    useToggleDiscussionMessageVisibility();

  const onHideToggle = () => {
    const newHiddenValue = !message.hidden;
    toggleMessageVisibility({
      discussionId: message.discussionId,
      messageId: message.id,
      newHiddenValue,
    });
    analytics.track(
      `DiscussionMessage ${newHiddenValue ? 'hidden' : 'unhidden'}`,
      {
        id: message.id,
        isAdmin: role === 'admin',
      },
    );
  };

  if (role === 'moderator' || role === 'admin') {
    if (collapsedView) {
      return (
        <PrimaryTooltip
          arrow
          placement="top"
          title={
            message.hidden
              ? i18next.t('ad_board.discussion.unhide')
              : i18next.t('ad_board.discussion.hide')
          }
        >
          <IconButton
            disableRipple
            onClick={(event) => {
              event.stopPropagation();
              onHideToggle();
            }}
            sx={{ color: palette.keyops.red.main, p: 0 }}
          >
            {' '}
            {message.hidden ? <Show sx={{ fontSize: '1.25rem' }} /> : <Hide />}
          </IconButton>
        </PrimaryTooltip>
      );
    }
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        {message.hidden && (
          <KeyopsSubTitle2 color={palette.keyops.red.main}>
            {i18next.t('ad_board.discussion.hideDetails')}
          </KeyopsSubTitle2>
        )}
        <Link
          onClick={() => onHideToggle()}
          component="button"
          sx={{
            color: palette.keyops.red.main,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >
          <Tooltip
            arrow
            disableInteractive={false}
            title={
              message.hidden
                ? i18next.t('ad_board.discussion.unhide')
                : i18next.t('ad_board.discussion.hide')
            }
          >
            {message.hidden ? <Show sx={{ fontSize: '1.25rem' }} /> : <Hide />}
          </Tooltip>
          <KeyopsSubTitle2 color={palette.keyops.red.main}>
            {message.hidden
              ? i18next.t('ad_board.discussion.unhide')
              : i18next.t('ad_board.discussion.hide')}
          </KeyopsSubTitle2>
        </Link>
      </Box>
    );
  }
};
