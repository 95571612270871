// theme.ts
import {
  createTheme,
  PaletteOptions,
  ThemeOptions,
} from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';
// import { baseColours } from './colours';
import { PaletteMode } from '@mui/material';

// Update Typography's prop types to include `body3` and `body2Black`
declare module '@mui/material/Typography' {
  // eslint-disable-next-line unicorn/prevent-abbreviations
  interface TypographyPropsVariantOverrides {
    body2Black: true;
    body3: true;
    h4: false;
  }
}
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true; // Keep existing
    sm: true;
    md: true;
    lg: true;
    xl: true;
    customMd: true; // Additional custom breakpoint
  }

  interface Palette {
    mode: PaletteMode;
    keyops: {
      background: {
        default: string;
        paper: string;
        highlight: string;
      };
      text: {
        primary: string;
        secondary: string;
        tertiary: string;
      };
      icon: {
        primary: string;
      };
      blue: {
        keyopsBlue: string;
        keyopsBlue50: string;
        arcticBlue: string;
        arcticBlue20: string;
        arcticBlue30: string;
        lightSteelBlue: string;
        lightSteelBlue20: string;
        lightSteelBlue70: string;
        midnightBlue: string;
        midnightBlue50: string;
        midnightBlue90: string;
      };
      purple: {
        main: string;
      };
      teal: string;
      black: {
        main: string;
        alpha600: string;
        alpha300: string;
      };
      white: {
        main: string;
        alpha800: string;
        alpha900: string;
      };
      gray: {
        main: string;
        50: string;
        100: string;
        200: string;
        300: string;
        400: string;
        500: string;
        600: string;
        700: string;
      };
      red: {
        main: string;
      };
    };
  }
  interface PaletteOptions {
    mode?: PaletteMode;
    keyops?: {
      background?: {
        default?: string;
        paper?: string;
        highlight?: string;
      };
      text?: {
        primary?: string;
        secondary?: string;
        tertiary?: string;
      };
      icon?: {
        primary?: string;
      };
      blue?: {
        keyopsBlue?: string;
        keyopsBlue50?: string;
        arcticBlue?: string;
        arcticBlue20?: string;
        arcticBlue30?: string;
        lightSteelBlue?: string;
        lightSteelBlue20?: string;
        lightSteelBlue70?: string;
        midnightBlue?: string;
        midnightBlue50: string;
        midnightBlue90?: string;
      };
      purple?: {
        main?: string;
      };
      teal?: string;
      black?: {
        main?: string;
        alpha600?: string;
        alpha300?: string;
      };
      white?: {
        main?: string;
        alpha800?: string;
        alpha900?: string;
      };
      gray?: {
        main?: string;
        50?: string;
        100?: string;
        200?: string;
        300?: string;
        400?: string;
        500?: string;
        600?: string;
        700?: string;
      };
      red?: {
        main?: string;
      };
    };
  }

  interface TypographyVariants {
    body3: React.CSSProperties;
    body2Black: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
    body2Black?: React.CSSProperties;
  }
}

// Shared typography settings
const typography = (palette: PaletteOptions): TypographyOptions => {
  const defaultThemeBreakpoints = createTheme().breakpoints;
  return {
    fontFamily: 'Poppins',
    button: {
      textTransform: 'none',
    },
    h1: {
      color: palette.keyops.text.primary,
      fontSize: '1.375rem', // 22px
      lineHeight: 1.45, // 32px
      fontWeight: 500,
      [`@media (max-width: ${defaultThemeBreakpoints?.values?.md}px)`]: {
        fontSize: '1.125rem', // 18px
        lineHeight: 1.333, // 24px
      },
    },
    h2: {
      color: palette.keyops.text.primary,
      fontSize: '1rem', // 16px
      fontWeight: 500,
      lineHeight: 1.5, // 24px
    },
    h3: {
      color: palette.keyops.text.primary,
      fontSize: '0.75rem', // 12px
      fontWeight: 400,
      textTransform: 'uppercase',
      lineHeight: 1.33, // 16px
    },
    subtitle1: {
      color: palette.keyops.text.secondary,
      fontSize: '0.875rem', // 14px
      fontWeight: 400,
      lineHeight: 1.143, // 16px
    },
    body1: {
      color: palette.keyops.text.primary,
      fontSize: '0.875rem', // 14px
      lineHeight: 1.71, // 24px
    },
    body2: {
      color: palette.keyops.text.secondary,
      fontSize: '1rem', // 16px
      lineHeight: 1.5, // 24px
      [`@media (max-width: ${defaultThemeBreakpoints?.values?.md}px)`]: {
        fontSize: '0.875rem', // 14px
        lineHeight: 1.143, // 16px
      },
    },
    body2Black: {
      color: palette.keyops.text.primary,
      fontSize: '1rem', // 16px
      lineHeight: 1.5, // 24px
      [`@media (max-width: ${defaultThemeBreakpoints?.values?.md}px)`]: {
        fontSize: '0.875rem', // 14px
        lineHeight: 1.143, // 16px
      },
    },
    body3: {
      fontSize: '0.875rem', // 14px
      lineHeight: 1.57, // 22px
    },
  };
};

// Shared theme properties
const sharedThemeProperties = (palette: PaletteOptions): ThemeOptions => ({
  breakpoints: {
    values: {
      ...createTheme().breakpoints.values, // Include default breakpoints
      customMd: 1028, // Add your custom breakpoint
    },
  },
  typography: typography(palette),
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          variants: [
            {
              props: { variant: 'outlined' },
              style: {
                color: '#3376D1',
              },
            },
          ],
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {},
      },
    },
  },
});

// Light theme palette
const lightThemePalette: PaletteOptions = {
  mode: 'light',
  secondary: {
    main: '#39BDAF',
  },
  keyops: {
    background: {
      default: '#FFFFFF',
      paper: '#FFFFFF',
      highlight: '#E0F0FF4D',
    },
    text: {
      primary: '#1A242B',
      secondary: '#7C8DB5',
      tertiary: '#3376D1',
    },
    icon: {
      primary: '#1A4B7B',
    },
    blue: {
      keyopsBlue: '#3376D1',
      keyopsBlue50: '#3376D180', //this isn't 90%
      arcticBlue: '#E0F0FF',
      arcticBlue20: '#E0F0FF33',
      arcticBlue30: '#E0F0FF4D',
      lightSteelBlue: '#7C8DB5',
      lightSteelBlue20: '#7C8DB533',
      lightSteelBlue70: '#7C8DB5B2',
      midnightBlue: '#1A4B7B',
      midnightBlue90: '#1A4B7BE5',
      midnightBlue50: '#1A4B7B80',
    },
    purple: {
      main: '#302B70',
    },
    teal: '#39BDAF',
    black: { main: '#1A242B', alpha600: '#0000007A', alpha300: '#00000029' },
    white: { main: '#FFFFFF', alpha800: '#ffffffcc', alpha900: '#FFFFFFEB' },
    gray: {
      main: '#B3B3B3',
      50: '#F7FAFC',
      100: '#EDF2F7',
      200: '#E2E8F0',
      300: '#CBD5E0',
      400: '#A0AEC0',
      500: '#718096',
      600: '#4A5568',
      700: '#2D3748',
    },
    red: {
      main: '#C53030',
    },
  },
};

// const darkThemePalette: PaletteOptions = {
//   mode: 'dark',
//   secondary: {
//     main: '#39BDAF',
//   },
//   keyops: {
//     background: {
//       default: '#1A202C',
//       paper: '#2D3747',
//       highlight: '#CBD5E0',
//     },
//     text: {
//       primary: '#FFFFFF',
//       secondary: '#FFFFFF',
//       tertiary: '#FFFFFF',
//     },
//     icon: {
//       primary: '#FFFFFF',
//     },
//     blue: {
//       keyopsBlue: '#3376D1',
//       keyopsBlue50: '#3376D180', //this isn't 90%
//       arcticBlue: '#E0F0FF',
//       arcticBlue20: '#E0F0FF33',
//       arcticBlue30: '#E0F0FF4D',
//       lightSteelBlue: '#7C8DB5',
//       lightSteelBlue20: '#7C8DB533',
//       midnightBlue: '#1A4B7B',
//       midnightBlue90: '#1A4B7BE5',
//       midnightBlue50: '#1A4B7B80',
//     },
//     teal: '#39BDAF',
//     black: { main: '#1A242B', alpha600: '#0000007A', alpha300: '#00000029' },
//     white: { main: '#FFFFFF', alpha800: '#ffffffcc', alpha900: '#FFFFFFEB' },
//     gray: {
//       main: '#B3B3B3',
//       50: '#F7FAFC',
//       100: '#EDF2F7',
//       200: '#E2E8F0',
//       300: '#CBD5E0',
//       400: '#A0AEC0',
//       500: '#718096',
//       600: '#4A5568',
//       700: '#2D3748',
//     },
//   },
// };

// Create light theme
const theme = createTheme({
  ...sharedThemeProperties(lightThemePalette),
  palette: lightThemePalette,
});

// const theme = createTheme({
//   ...sharedThemeProperties(darkThemePalette),
//   palette: darkThemePalette,
// });

export { theme };
