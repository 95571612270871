import React, { MouseEvent } from 'react';
import { Badge, IconButton, useTheme } from '@mui/material';

import { PrimaryTooltip } from '../PrimaryTooltip';
import i18n from '../../languages/i18n.config';
import { Bell } from '../../icons/nova/Bell';
import { hoverItemStyle } from '../../styles';

export const NotificationsButton = ({
  toggleNotificationsUI,
  areNotificationsUnread,
}: {
  toggleNotificationsUI: (event: MouseEvent<HTMLButtonElement>) => void;
  areNotificationsUnread: boolean;
}) => {
  const { palette } = useTheme();

  return (
    <PrimaryTooltip title={i18n.t('navbar.hover.notifications')}>
      <IconButton
        data-testid="notifications-button"
        aria-label="notifications"
        sx={{
          ...hoverItemStyle(),
          color: palette.keyops.icon.primary,
          px: 0.5,
        }}
        onClick={toggleNotificationsUI}
        disableFocusRipple
        disableTouchRipple
        disableRipple
      >
        {areNotificationsUnread ? (
          <Badge data-testid="unread-dot" color="secondary" variant="dot">
            <Bell data-testid="notification-bell" />
          </Badge>
        ) : (
          <Bell data-testid="notification-bell" />
        )}
      </IconButton>
    </PrimaryTooltip>
  );
};
