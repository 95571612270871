import React, { RefObject, useState, useEffect } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import ReactPlayer from 'react-player';
import { useOneDiscussion } from '../../hooks/useDiscussion';
import { Loader } from '../Loader';
import i18next from '../../languages/i18n.config';
import { KeyopsSubTitle1 } from '../Headers';
import { DiscussionMessageForm } from '../DiscussionMessageForm';
import { DiscussionChatMessages } from '../DiscussionChatMessages';
import { useAdBoardContext } from '../../context';
import { countMessages } from '../../utils/discussion';

export const VideoDiscussion = ({
  discussionId,
  playerRef,
  requiredCount,
}: {
  discussionId: string;
  playerRef: RefObject<ReactPlayer | null>;
  requiredCount: number;
}) => {
  const { userId, role } = useAdBoardContext();
  const {
    data: discussion,
    isLoading,
    isError,
  } = useOneDiscussion(discussionId);

  const theme = useTheme();

  const [currentUserCommentCount, setCurrentUserCommentCount] = useState(0);

  useEffect(() => {
    if (discussion) {
      const count = discussion?.messages?.reduce((sum, message) => {
        if (message.userId === userId) {
          sum++;
        }
        return sum;
      }, 0);
      setCurrentUserCommentCount(count);
    } else {
      setCurrentUserCommentCount(0);
    }
  }, [discussion, userId]);

  if (isLoading) return <Loader />;
  if (isError) return <div>{i18next.t('common.error')}</div>;

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          sx={{
            color: theme.palette.keyops.gray[600],
            fontSize: '14px',
            my: 1,
          }}
        >
          {i18next.t('video.comments.instructions')}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: theme.palette.keyops.gray[600],
            fontSize: '14px',
            my: 1,
          }}
        >
          {i18next.t('video.comments.requiredCount', {
            count: requiredCount,
            commentCount: Math.min(currentUserCommentCount, 3),
          })}
        </Typography>
      </Box>
      <DiscussionMessageForm
        discussionId={discussionId}
        playerRef={playerRef}
      />
      <KeyopsSubTitle1>
        {/* Count all messages for moderators and admins, count only not hidden messages otherwise */}
        {i18next.t('video.comments.count', {
          count: countMessages(discussion?.messages, role),
        })}
      </KeyopsSubTitle1>
      <DiscussionChatMessages
        pageDisplay={true}
        messages={discussion?.messages}
      />
    </>
  );
};
