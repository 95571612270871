import React from 'react';
import { Stack, Link } from '@mui/material';

import i18next from '../../languages/i18n.config';
import { KeyopsSubTitle2 } from '../Headers';

interface AttendeeDiscussionMessageCTAProperties {
  collapsedView?: boolean;
  onReplyToggle?: () => void;
  setShowMessageForm: React.Dispatch<React.SetStateAction<boolean>>;
  showMessageForm: boolean;
}

export const AttendeeDiscussionMessageCTA = ({
  collapsedView,
  onReplyToggle = () => {},
  setShowMessageForm,
  showMessageForm,
}: AttendeeDiscussionMessageCTAProperties): React.JSX.Element => {
  if (collapsedView) return;

  return (
    <Stack
      pb="8px"
      width="100%"
      direction="row"
      spacing={1}
      alignItems="flex-start"
    >
      {/* Reply button */}
      {
        <Link
          onClick={() => {
            setShowMessageForm(!showMessageForm);
            onReplyToggle();
          }}
          underline="none"
          style={{ cursor: 'pointer' }}
        >
          <KeyopsSubTitle2 color="primary">
            {i18next.t('ad_board.discussion.reply')}
          </KeyopsSubTitle2>
        </Link>
      }
    </Stack>
  );
};
