/* eslint-disable unicorn/filename-case */
import React, { MouseEvent } from 'react';
import { createColumnHelper } from '@tanstack/table-core';
import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { ModerationFlagsDto } from '@keyops-hcp/dtos';

import { Close, FlagSolid } from '../../icons';
import { formatDate } from '../../utils/datetime';
import { useUpdateOneModerationFlags } from '../../hooks';
import { Check } from '../../icons/nova/Check';
import { PrimaryTooltip } from '../PrimaryTooltip';

export interface ActionCenterTableData extends ModerationFlagsDto {
  section: string;
  actions: string;
}

const columnHelper = createColumnHelper<ActionCenterTableData>();

export const useActionCenterTableColumns = () => {
  const theme = useTheme();

  // Update ModerationFlags status
  const { mutate: updateOneModerationFlags } = useUpdateOneModerationFlags();
  const handleUpdateModerationFlagStatus = (
    moderationFlagId: string,
    dataToUpdate: Partial<ModerationFlagsDto>,
  ) => {
    updateOneModerationFlags({ id: moderationFlagId, dataToUpdate });
  };

  // Build columns
  const columns = React.useMemo(
    () => [
      columnHelper.accessor('section', {
        cell: (info) => (
          <Typography fontWeight={400} fontSize={'14px'} lineHeight={'22px'}>
            {info.getValue()}
          </Typography>
        ),
        header: 'Section',
      }),
      columnHelper.accessor('type', {
        cell: (info) => (
          <Box sx={{ justifySelf: 'center' }}>
            {info.getValue() === 'urgent' && (
              <FlagSolid sx={{ fontSize: '15px' }} />
            )}
          </Box>
        ),
        header: () => <Box sx={{ justifySelf: 'center' }}>Priority</Box>,
      }),
      columnHelper.accessor('createdAt', {
        cell: (info) => (
          <Typography fontWeight={400} fontSize={'14px'} lineHeight={'22px'}>
            {formatDate(info.getValue())}
          </Typography>
        ),
        header: 'Date',
      }),
      columnHelper.accessor('recommendation', {
        cell: (info) => (
          <Typography
            fontWeight={400}
            fontSize={'14px'}
            lineHeight={'22px'}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {info.getValue()}
          </Typography>
        ),
        header: 'Recommendation',
      }),
      columnHelper.accessor('actions', {
        cell: (info) => (
          <Stack
            direction="row"
            justifyContent={'center'}
            alignItems={'center'}
            spacing={1}
          >
            {[
              {
                label: 'Complete',
                icon: (
                  <Check
                    sx={{
                      px: '1px',
                      fontSize: '14px',
                      color: theme.palette.keyops.blue.keyopsBlue,
                    }}
                  />
                ),
                onClick: (event: MouseEvent) => {
                  event.stopPropagation(); // Stopping the navigate triggered by click on row
                  handleUpdateModerationFlagStatus(info.row.original.id, {
                    status: 'completed',
                  });
                },
              },
              {
                label: 'Dismiss',
                icon: (
                  <Close
                    sx={{
                      px: '1px',
                      fontSize: '19px',
                      color: theme.palette.keyops.blue.keyopsBlue,
                    }}
                  />
                ),
                onClick: (event: MouseEvent) => {
                  event.stopPropagation(); // Stopping the navigate triggered by click on row

                  handleUpdateModerationFlagStatus(info.row.original.id, {
                    status: 'dismissed',
                  });
                },
              },
            ].map(({ label, icon, onClick }) => (
              <PrimaryTooltip key={label} title={label} placement="top">
                <IconButton aria-label={label} onClick={onClick}>
                  {icon}
                </IconButton>
              </PrimaryTooltip>
            ))}
          </Stack>
        ),
        header: () => (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Action
          </Box>
        ),
      }),
    ],
    [],
  );

  return columns;
};
