import React, { useMemo } from 'react';
import { Loading } from 'react-admin';
import { Box, Container, Stack, useTheme } from '@mui/material';
import { KeyopsHeader2, KeyopsSubTitle2 } from '@keyops-hcp/ui-components';
import { AdBoardDto, AdBoardEngagementParticipantsDto } from '@keyops-hcp/dtos';

const SummarySection = ({
  filters,
  isLoading,
  adBoardData,
  participantsData,
}: {
  filters: { [key: string]: string | number };
  isLoading: boolean;
  adBoardData: AdBoardDto;
  participantsData: AdBoardEngagementParticipantsDto[];
}) => {
  const { palette } = useTheme();
  const filtersApplied = Object.keys(filters).length > 0;

  // Memoized participant data calculations
  const engagementCalculations = useMemo(() => {
    const today = new Date().setHours(0, 0, 0, 0);
    const totalSections = adBoardData?.sections?.length || 0;

    let activeToday = 0;
    const inProgressParticipants: AdBoardEngagementParticipantsDto[] = [];
    const completedParticipants: AdBoardEngagementParticipantsDto[] = [];
    const filteredInProgressParticipants: AdBoardEngagementParticipantsDto[] =
      [];
    const filteredCompletedParticipants: AdBoardEngagementParticipantsDto[] =
      [];

    const filteredSection = adBoardData?.sections?.find(
      (section) =>
        section.index === Number.parseInt(filters.sectionIndex?.toString()),
    );

    for (const participant of participantsData) {
      const { updatedAt, progress } = participant;
      const sectionProgress = progress?.progressDetails?.sectionProgress || [];
      const finishedSections = sectionProgress.filter(
        (section) => section.finished,
      ).length;

      if (updatedAt && new Date(updatedAt).setHours(0, 0, 0, 0) === today) {
        activeToday++;
      }

      if (finishedSections < totalSections) {
        inProgressParticipants.push(participant);
      } else if (finishedSections === totalSections) {
        completedParticipants.push(participant);
      }

      // Filter based on sectionId
      const sectionProgressForFiltered = sectionProgress.find(
        (progressSection) => progressSection.sectionId === filteredSection?.id,
      );

      if (sectionProgressForFiltered?.finished) {
        filteredCompletedParticipants.push(participant);
      } else {
        filteredInProgressParticipants.push(participant);
      }
    }

    const totalParticipants = participantsData.length;
    const completionPercentage = totalParticipants
      ? Math.round((100 * completedParticipants.length) / totalParticipants)
      : 0;

    return {
      totalParticipants,
      activeToday,
      inProgressParticipants,
      completedParticipants,
      filteredInProgressParticipants,
      filteredCompletedParticipants,
      completionPercentage,
    };
  }, [participantsData, adBoardData, filters]);

  const {
    totalParticipants,
    activeToday,
    inProgressParticipants,
    completedParticipants,
    filteredInProgressParticipants,
    filteredCompletedParticipants,
    completionPercentage,
  } = engagementCalculations;

  // Memoized stats for display
  const stats = useMemo(() => {
    const filteredStats = [
      { label: 'Participants', value: totalParticipants },
      { label: 'Active today', value: activeToday },
      { label: 'Completion rate', value: `${completionPercentage}%` },
    ];

    // Conditionally add "In progress" and "Completed" if filters are empty
    if (!filtersApplied) {
      filteredStats.splice(
        2,
        0, // Insert these stats at index 2
        {
          label: 'In progress',
          value: inProgressParticipants.length,
        },
        {
          label: 'Completed',
          value: completedParticipants.length,
        },
      );
    }

    return filteredStats;
  }, [
    totalParticipants,
    activeToday,
    inProgressParticipants,
    completedParticipants,
    completionPercentage,
    filteredInProgressParticipants,
    filteredCompletedParticipants,
    filtersApplied,
  ]);

  return (
    <Container maxWidth={filtersApplied ? 'xs' : 'sm'} sx={{ mt: 2 }}>
      <Box
        sx={{
          border: `1px solid ${palette.keyops.gray[200]}`,
          borderRadius: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: 2.5,
          py: 1.5,
          gap: 2,
        }}
      >
        {stats.map(({ label, value }, index) => (
          <Stack key={index} gap={1}>
            <KeyopsSubTitle2>{label}</KeyopsSubTitle2>
            <KeyopsHeader2>
              {isLoading ? <Loading /> : (value ?? '-')}
            </KeyopsHeader2>
          </Stack>
        ))}
      </Box>
    </Container>
  );
};

export default SummarySection;
