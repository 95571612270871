import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Stack, Box, Typography, Button, useTheme } from '@mui/material';
import { FiChevronLeft } from 'react-icons/fi';
import { AdBoardSectionTaskInfo } from '../AdBoardSectionTaskInfo';
import i18next from '../../languages/i18n.config';
import { DiscussionMessage } from '../DiscussionMessage';
import { useOneDiscussion } from '../../hooks/useDiscussion';
import { DiscussionMessageForm } from '../DiscussionMessageForm';
import {
  fetchUrlHashData,
  getMessagesToDisplay,
  scrollToMessageId,
} from '../../utils/discussion';
import { useAdBoardContext } from '../../context';

export const TopicDisplay = ({
  topicId,
  setIsFullScreenData,
  sectionNavigate,
}: {
  topicId: string;
  setIsFullScreenData: (isFullScreen: boolean) => void;
  sectionNavigate: (contentId?: string) => void;
}) => {
  const { palette } = useTheme();
  const location = useLocation();
  const { data: content } = useOneDiscussion(topicId); //NICE TO HAVE: add loading + error ui state
  const { role } = useAdBoardContext();

  const handleBackClick = () => {
    sectionNavigate();
    setIsFullScreenData(false);
  };
  const messagesToDisplay = getMessagesToDisplay(content?.messages, role);

  const { msgId } = fetchUrlHashData();

  useEffect(() => {
    if (msgId) {
      scrollToMessageId(msgId);
    }
  }, [msgId, location]);

  return (
    <Stack spacing={2}>
      <Button
        aria-label="back_to_topics"
        startIcon={<FiChevronLeft />}
        onClick={handleBackClick}
      >
        {i18next.t('ad_board.discussion.back_to_topics')}
      </Button>
      <Typography fontWeight={400} fontSize="16px" lineHeight="24px">
        {content?.topic}
      </Typography>
      <AdBoardSectionTaskInfo>
        {i18next.t('ad_board.discussion.objectives')}
      </AdBoardSectionTaskInfo>
      <DiscussionMessageForm discussionId={topicId} />
      {/* Use prev component here! */}
      <Stack direction="column" gap={2}>
        {messagesToDisplay?.map((message) => (
          <Box
            key={message.id}
            display="flex"
            alignItems="center"
            border={message.hidden ? '0px' : '1px solid'}
            borderColor={palette.keyops.blue.lightSteelBlue20}
            borderRadius={1}
            p={3}
            pb={0}
            sx={{
              backgroundColor: message.hidden
                ? palette.keyops.blue.lightSteelBlue20
                : 'none',
            }}
          >
            <DiscussionMessage
              key={message?.id}
              highlighted={msgId === message?.id}
              message={message}
            />
          </Box>
        ))}
      </Stack>
    </Stack>
  );
};
