import React from 'react';
import { Box, Typography, useTheme, BoxProps } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import i18next from '../../languages/i18n.config';
import { Plus, Rewards } from '../../icons';

export const EngagementEarningsDisplay = ({
  honorarium,
  points,
  renderMultiLine,
  ...properties
}: {
  honorarium?: number;
  points?: number;
  renderMultiLine?: boolean;
} & BoxProps) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const defaultedProperties = {
    borderRadius: properties.borderRadius ?? '8px',
    p: properties.p ?? 2,
    mb: 2,
    textAlign: properties.textAlign ?? 'center',
    ...properties,
  };
  return (
    <Box {...defaultedProperties}>
      <Box>
        {
          <Typography color={palette.keyops.blue.keyopsBlue}>
            {t('engagementEarnings.earn')}
          </Typography>
        }{' '}
        {!!honorarium && (
          <Typography
            fontSize={{
              xs: '16px',
              sm: '16px',
              md: renderMultiLine ? '20px' : '18px',
            }}
            fontWeight={600}
            lineHeight={1.2}
            component={renderMultiLine ? undefined : 'span'}
            color={palette.keyops.blue.keyopsBlue}
          >
            {i18next.t('general.dollarAmount', {
              amount: honorarium,
            })}
          </Typography>
        )}
        {!!points && (
          <Typography
            fontSize="14px"
            component={renderMultiLine ? undefined : 'span'}
            lineHeight={1}
            color={palette.keyops.blue.keyopsBlue}
          >
            {/* plus only needed if both hono and points */}
            {!!honorarium && (
              <Plus
                sx={{
                  fontSize: { xs: '11px', sm: '11px', md: '14px' },
                  px: {
                    xs: renderMultiLine ? undefined : '4px',
                    sm: renderMultiLine ? undefined : '4px',
                    md: undefined,
                  },
                }}
              />
            )}
            <Rewards
              sx={{
                fontSize: '14px',
                color: palette.keyops.blue.keyopsBlue,
                px: '2px',
              }}
            />
            <Trans
              i18n={i18next}
              i18nKey="engagementEarnings.pointsAward"
              values={{ points }}
              components={{
                pointsElement: (
                  <Typography
                    component="span"
                    fontSize={{ xs: '16px', sm: '16px', md: '18px' }}
                    fontWeight={600}
                    color={palette.keyops.blue.keyopsBlue}
                  />
                ),
              }}
            />
          </Typography>
        )}
      </Box>
    </Box>
  );
};
