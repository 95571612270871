import { Theme } from '@mui/material';
import {
  highlight,
  highlight2,
  hoverHighlight,
  hoverHighlight2,
} from '@keyops-hcp/constants';

export const useStyles = () => ({
  contentWrapper: {
    display: 'flex',
    position: 'relative', // <- Very important for comment anchoring
  },
  collapseSection: {
    background: '#f5f5f5',
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
  },
  closeButtonAlign: { display: 'flex', justifyContent: 'end' },
  commentWrapper: {
    p: 2,
    pt: 0,
    width: '450px',
  },
});

export const useAddCommentButtonStyles = (buttonPosition: {
  x: number;
  y: number;
}) => ({
  position: 'absolute',
  left: buttonPosition.x,
  top: buttonPosition.y,
  zIndex: 1000,
});

export const useHTMLDivStyles = (selected: boolean) => ({
  flex: 1,
  padding: '20px',
  border: '1px solid #ccc',
  borderRadius: '5px',
  borderTopRightRadius: selected ? '0px' : '5px',
  borderBottomRightRadius: selected ? '0px' : '5px',
  lineHeight: '1.6',
  transition: 'margin-right 0.3s ease',
});

export const useMessageBoxStyles = (
  theme: Theme,
  selected: boolean,
  top: number,
  hidden: boolean,
) => {
  let border;

  if (selected) {
    border = '2px solid';
  } else if (hidden) {
    border = '0px';
  } else {
    border = '1px solid';
  }

  return {
    position: 'absolute',
    top,
    display: 'flex',
    alignItems: 'center',
    border,
    width: '390px',
    backgroundColor: hidden
      ? theme.palette.keyops.blue.lightSteelBlue20
      : theme.palette.keyops.white.main,
    borderColor: selected
      ? theme.palette.keyops.blue.midnightBlue
      : theme.palette.keyops.blue.lightSteelBlue20,
    borderRadius: 1,
    p: 2,
    pb: 0,
    mb: 1,
    ml: selected ? -1 : 0,
    mr: selected ? 1 : 0,
    transition: 'margin 0.3s ease-in-out',
  };
};

export const useHighlightStyles = (selectedMarkupMessageId: string) => {
  return `
        .${highlight} {
          background-color: #fff9c4;
          text-decoration: underline solid #ffc107;
          font: inherit;
          color: inherit;
        }
        .${highlight2} {
          background-color: #ffcdd2;
          text-decoration: underline solid #f44336;
          font: inherit;
          color: inherit;
        }
        .${hoverHighlight} {
          background-color: #d1c4e9;
          text-decoration: underline solid #7e57c2;
          color: #5e35b1;
        }
        .${hoverHighlight2} {
          background-color: #ffcdd2;
          text-decoration: underline solid #f44336;
          color: #d32f2f;
        }
        span[class*="${selectedMarkupMessageId}"] {
          display: inline;
          background-image: linear-gradient(teal, teal);
          background-repeat: no-repeat;
          background-size: 100% 4px;
          background-position: 0 100%;
          border-radius: 2px;
          padding-bottom: 4px;
          box-decoration-break: clone;
        }
        `;
};
