import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { AdBoardSectionGroupDto, SectionDto } from '@keyops-hcp/dtos';
import { useParams } from 'react-router-dom';

import i18next from '../../languages/i18n.config';
import { AdBoardSectionDisplay } from '../AdBoardSectionDisplay';
import { KeyopsHeader2, KeyopsSubTitle1, KeyopsSubTitle2 } from '../Headers';
import { useAdBoardContext } from '../../context';
import { Loader } from '../Loader';
import { fetchUrlHashData } from '../../utils/discussion';

const TitleSection = ({
  sectionGroups,
  currentSectionGroup,
  currentSection,
}: {
  sectionGroups: AdBoardSectionGroupDto[];
  currentSectionGroup: AdBoardSectionGroupDto;
  currentSection: SectionDto;
}) => {
  return (
    <Stack spacing={1} mb={2}>
      <KeyopsSubTitle2>
        {i18next.t('ad_board.sections_view.section_number', {
          currentSection: currentSectionGroup?.index + 1,
          totalSections: sectionGroups.length,
        })}{' '}
        | {currentSectionGroup?.title}
      </KeyopsSubTitle2>
      <KeyopsHeader2 fontSize={18}>{currentSection.title}</KeyopsHeader2>
      {currentSection.description && (
        <KeyopsSubTitle1>{currentSection.description}</KeyopsSubTitle1>
      )}
    </Stack>
  );
};

export const AdBoardSectionsToggle = ({
  currentSection,
  setCurrentSectionId,
  currentContentId,
  sectionDrawerOpen,
  setSectionDrawerOpen,
  setDiscussionDrawerOpen,
}: {
  currentSection: SectionDto;
  setCurrentSectionId: (
    id: string,
    contentId?: string,
    gd?: boolean,
    messageId?: string,
  ) => void;
  currentContentId?: string;
  setDiscussionDrawerOpen?: (input: boolean) => void;
  sectionDrawerOpen?: boolean;
  setSectionDrawerOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [fullScreenData, setFullScreenData] = useState(false);
  const setIsFullScreenData = (isFullScreen: boolean) =>
    setFullScreenData(isFullScreen);

  const { contentId } = useParams();

  const {
    isLoading,
    isError,
    sectionGroupsData: sectionGroups,
    sectionsData: sections,
    adBoardProgress,
  } = useAdBoardContext();

  useEffect(() => {
    if (sections && sections.length > 0 && currentSection) {
      const { gd, msgId } = fetchUrlHashData();
      if (gd && setDiscussionDrawerOpen) setDiscussionDrawerOpen(true);
      setCurrentSectionId(currentSection.id, contentId, gd, msgId);
    }
  }, [sections, currentSection]);

  const [currentSectionGroup, setCurrentSectionGroup] =
    useState<AdBoardSectionGroupDto>();

  useEffect(() => {
    if (sectionGroups && sectionGroups.length > 0 && currentSection) {
      const sectionGroup = sectionGroups.find(
        (sectionGroup) => sectionGroup.id === currentSection.sectionGroupId,
      );
      setCurrentSectionGroup(sectionGroup);
    }
  }, [sectionGroups, sections, currentSection]);

  if (isLoading || !currentSection) return <Loader />;
  if (isError) return <div>{i18next.t('common.error')}</div>;

  const currentSectionProgress =
    adBoardProgress?.progressDetails?.sectionProgress.find(
      (progress) => progress.sectionId === currentSection.id,
    );

  return (
    <>
      {!fullScreenData && (
        <TitleSection
          sectionGroups={sectionGroups}
          currentSectionGroup={currentSectionGroup}
          currentSection={currentSection}
        />
      )}
      <AdBoardSectionDisplay
        section={currentSection}
        setIsFullScreenData={setIsFullScreenData}
        setCurrentSectionId={setCurrentSectionId}
        currentContentId={currentContentId}
        currentSectionProgress={currentSectionProgress}
        sectionDrawerOpen={sectionDrawerOpen}
        setSectionDrawerOpen={setSectionDrawerOpen}
      />
    </>
  );
};
