import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Show = (properties: SvgIconProps) => (
  <SvgIcon {...properties}>
    <svg
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99861 1.39286C6.18766 1.39286 4.6989 2.25179 3.55733 3.35737C2.48798 4.3962 1.74915 5.62946 1.37141 6.5C1.74915 7.37054 2.48798 8.60379 3.55455 9.64263C4.6989 10.7482 6.18766 11.6071 7.99861 11.6071C9.80957 11.6071 11.2983 10.7482 12.4399 9.64263C13.5092 8.60379 14.2481 7.37054 14.6258 6.5C14.2481 5.62946 13.5092 4.3962 12.4427 3.35737C11.2983 2.25179 9.80957 1.39286 7.99861 1.39286ZM2.64908 2.33884C3.9573 1.06786 5.75436 0 7.99861 0C10.2429 0 12.0399 1.06786 13.3481 2.33884C14.648 3.60112 15.5174 5.10714 15.9313 6.14308C16.0229 6.37232 16.0229 6.62768 15.9313 6.85692C15.5174 7.89286 14.648 9.40178 13.3481 10.6612C12.0399 11.9321 10.2429 13 7.99861 13C5.75436 13 3.9573 11.9321 2.64908 10.6612C1.34919 9.40178 0.479819 7.89286 0.068744 6.85692C-0.0229147 6.62768 -0.0229147 6.37232 0.068744 6.14308C0.479819 5.10714 1.34919 3.59821 2.64908 2.33884ZM7.99861 8.82143C9.22628 8.82143 10.2206 7.78259 10.2206 6.5C10.2206 5.21741 9.22628 4.17857 7.99861 4.17857C7.97917 4.17857 7.9625 4.17857 7.94306 4.17857C7.97917 4.32656 7.99861 4.48326 7.99861 4.64286C7.99861 5.66719 7.20146 6.5 6.22099 6.5C6.06822 6.5 5.91824 6.47969 5.77658 6.44196C5.77658 6.46228 5.77658 6.47969 5.77658 6.5C5.77658 7.78259 6.77094 8.82143 7.99861 8.82143ZM7.99861 2.78571C8.94152 2.78571 9.84581 3.17704 10.5126 3.8736C11.1793 4.57017 11.5539 5.51491 11.5539 6.5C11.5539 7.48509 11.1793 8.42983 10.5126 9.1264C9.84581 9.82296 8.94152 10.2143 7.99861 10.2143C7.0557 10.2143 6.15141 9.82296 5.48467 9.1264C4.81793 8.42983 4.44336 7.48509 4.44336 6.5C4.44336 5.51491 4.81793 4.57017 5.48467 3.8736C6.15141 3.17704 7.0557 2.78571 7.99861 2.78571Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);
