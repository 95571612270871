import React from 'react';

import { Avatar, useTheme } from '@mui/material/';
import { UserType } from '@keyops-hcp/constants';

type KeyOpsAvatarProperties = {
  text: string;
  role?: UserType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} & { [x: string]: any };

export const KeyOpsAvatar = ({
  text,
  role,
  ...properties
}: KeyOpsAvatarProperties) => {
  const { palette } = useTheme();
  return (
    <Avatar
      sx={{
        width: properties.width ?? 30,
        height: properties.height ?? 30,
        backgroundColor:
          role === 'moderator'
            ? palette.keyops.blue.midnightBlue
            : palette.keyops.blue.lightSteelBlue,
        color: palette.keyops.white.main,
        fontSize: 12,
        fontWeight: 500,
        ...properties,
      }}
    >
      {text}
    </Avatar>
  );
};
