import React from 'react';
import { Box, Divider, IconButton, useTheme } from '@mui/material';
import { FiMessageCircle } from 'react-icons/fi';

import { COMPONENT_FEATURE_FLAGS } from '../../utils/component-feature-flags';
import { Attendees } from '../Attendees';
import { Notifications } from '../Notifications';
import { useAdBoardContext } from '../../context';
import { CalendarCheck, TaskList } from '../../icons';

interface MobileAdBoardTopBarProperties {
  toggleDiscussionDrawer: (isDrawerOpen: boolean) => void;
  toggleSectionDrawer: () => void;
  toggleActionCenterDrawer: (newOpen: boolean) => void;
  isSectionDrawerOpen: boolean;
  isDiscussionDrawerOpen: boolean;
  isActionCenterOpen: boolean;
}

export const MobileAdBoardTopBar = ({
  toggleDiscussionDrawer,
  toggleSectionDrawer,
  toggleActionCenterDrawer,
  isSectionDrawerOpen,
  isDiscussionDrawerOpen,
  isActionCenterOpen,
}: MobileAdBoardTopBarProperties): React.JSX.Element => {
  const { palette } = useTheme();
  const { role, shouldShowMainHcpNav } = useAdBoardContext();

  return (
    <Box display={'flex'} alignItems={'center'}>
      <Attendees />
      {COMPONENT_FEATURE_FLAGS.ADBOARD_DISCUSSION && (
        <IconButton
          data-testid="discussion-board-button"
          aria-label="discussion-board-button"
          sx={{
            color: isDiscussionDrawerOpen
              ? palette.keyops.teal
              : palette.keyops.blue.midnightBlue,
            mt: -0.2,
          }}
          onClick={() => toggleDiscussionDrawer(false)} // REFACTOR: add discussion board state change here
        >
          <FiMessageCircle fontSize={25} />
        </IconButton>
      )}
      {/* REFACTOR: remove this if we do not need section drawer in mobile view */}
      {/* REFACTOR: and if we need it in the mobile view than we will have to fix styles in the
                SectionDrawer component  */}
      <IconButton
        data-testid="section-drawer-toggle-button"
        aria-label="section-drawer-toggle-button"
        sx={{
          color: isSectionDrawerOpen
            ? palette.keyops.teal
            : palette.keyops.blue.midnightBlue,
          mt: -0.2,
          mr: 0.5,
        }}
        onClick={() => toggleSectionDrawer()}
      >
        <CalendarCheck
          sx={{
            fontSize: '25px',
          }}
        />
      </IconButton>
      {/* No need to display the Notification in the Admin Preview */}
      {shouldShowMainHcpNav() && <Notifications />}
      {role === 'moderator' && (
        <>
          <Divider orientation="vertical" sx={{ mx: 1 }} flexItem />
          <IconButton
            data-testid="moderator-recommendations-toggle-button"
            aria-label="moderator-recommendations-toggle-button"
            onClick={() => toggleActionCenterDrawer(true)}
            sx={{
              color: isActionCenterOpen
                ? palette.keyops.teal
                : palette.keyops.blue.midnightBlue,
              pl: 1,
            }}
            disableFocusRipple
            disableTouchRipple
            disableRipple
          >
            <TaskList
              sx={{
                fontSize: '23px',
              }}
            />
          </IconButton>
        </>
      )}
    </Box>
  );
};
