// Onboarding
export const ONBOARDING_ROOT = '/onboarding';
export const GOOGLE_ONBOARDING_ROOT = '/google-onboarding';
export const VERIFY_ONBOARDING_LINK = '/onboarding/verifyOnboardingLink';
export const VALIDATE_EMAIL = '/user/validEmail';
export const VALIDATE_WORK_EMAIL = '/user/validWorkEmail';
export const VALIDATE_LICENSE_NUMBER = '/user/validLicenseNumber';

// Notifications Endpoints
export const ALL_NOTIFICATIONS = '/notifications';
export const UPDATE_NOTIFICATIONS_READ_STATUS = '/notifications/read';

// Video Endpoints
export const VIDEOS_ENDPOINT = '/videos';
export const VIDEO_COMMENTS_ENDPOINT = '/video-comments';

// Attachments Endpoints
export const ATTACHMENTS_ENDPOINT = '/attachments';

// Surveys Endpoints
export const SURVEYS_ENDPOINT = '/surveys';

// Ad Boards Endpoints
export const AD_BOARDS_ENDPOINT = '/ad-boards';

// Sections Endpoint
export const SECTIONS_ENDPOINT = '/sections';

// Participants Endpoint
export const PARTICIPANTS_ENDPOINT = '/participants';

// Discussions Endpoint
export const DISCUSSIONS_ENDPOINT = '/discussions';

// Polls Endpoint
export const POLLS_ENDPOINT = '/polls';

export const MODERATION_FLAGS_ENDPOINT = '/moderation-flags';
