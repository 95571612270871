import React, { ChangeEvent, useState } from 'react';
import { Box, Container, SelectChangeEvent, Tab, Tabs } from '@mui/material';
import { AdBoardDto, AdBoardEngagementParticipantsDto } from '@keyops-hcp/dtos';

import ParticipantsList from './ParticipantsList';
import ProgressFeed from './ProgressFeed';

interface CustomTabPanelProperties {
  children?: React.ReactNode;
  value: number;
  index: number;
  id: string;
  ariaLabel: string;
}

const CustomTabPanel = ({
  children,
  value,
  index,
  id,
  ariaLabel,
}: CustomTabPanelProperties) => (
  <div
    id={`${id}-tabpanel`}
    role="tabpanel"
    aria-labelledby={ariaLabel}
    hidden={value !== index}
  >
    {value === index && <Box py={1.5}>{children}</Box>}
  </div>
);

const EngagementDetailsSection = ({
  handleIndividualFeed,
  adBoardData,
  participantsData,
  filters,
  handleSearchChange,
  clearFilters,
}: {
  handleIndividualFeed: (value: number | null) => void;
  adBoardData: AdBoardDto;
  participantsData: AdBoardEngagementParticipantsDto[];
  filters: { [key: string]: string | number };
  handleSearchChange: (
    event:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>,
    field: string,
  ) => void;
  clearFilters: () => void;
}) => {
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    clearFilters();
    setTabValue(newValue);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 3 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          aria-label="engagement-details-section-tabs"
          value={tabValue}
          onChange={handleTabChange}
        >
          <Tab
            id="participant-tab"
            aria-controls="participant-tabpanel"
            label="Participants"
          />
          <Tab id="feed-tab" aria-controls="feed-tabpanel" label="Feed" />
        </Tabs>
      </Box>
      <CustomTabPanel
        value={tabValue}
        index={0}
        id="participant-tab"
        ariaLabel="participant-tab"
      >
        <ParticipantsList
          handleParticipantFeed={handleIndividualFeed}
          participantsData={participantsData}
        />
      </CustomTabPanel>
      <CustomTabPanel
        value={tabValue}
        index={1}
        id="feed-tab"
        ariaLabel="feed-tab"
      >
        <ProgressFeed
          filters={filters}
          handleSearchChange={handleSearchChange}
          adBoardData={adBoardData}
          participantsData={participantsData}
        />
      </CustomTabPanel>
    </Container>
  );
};

export default EngagementDetailsSection;
