/* eslint-disable unicorn/filename-case */
import React from 'react';
import { Box, Typography } from '@mui/material';
import { AdBoardDto } from '@keyops-hcp/dtos';

import { HTMLOutput } from '../../../../HTMLOutput';
import { KeyopsHeader2 } from '../../../../Headers';
import { AdBoardActivitiesContent } from '../../AdBoardActivities';
import i18next from '../../../../../languages/i18n.config';

type AdBoardObjectivesAndActivitiesProperties = {
  adBoard: AdBoardDto;
};

export const AdBoardObjectivesAndActivities: React.FC<
  AdBoardObjectivesAndActivitiesProperties
> = ({ adBoard }) => {
  return (
    <>
      {adBoard?.objectives && (
        <Box>
          <KeyopsHeader2
            id="objectives-content"
            data-testid="objectives-content"
          >
            {i18next.t('ad_board.landing_page.objectives')}
          </KeyopsHeader2>
          <Typography>
            <HTMLOutput html={adBoard?.objectives} />
          </Typography>
        </Box>
      )}
      <Box>
        <KeyopsHeader2
          id="activities-content"
          data-testid="activities-content"
          gutterBottom
        >
          {i18next.t('ad_board.landing_page.activities')}
        </KeyopsHeader2>
        <AdBoardActivitiesContent adBoard={adBoard} />
      </Box>
    </>
  );
};
