/* eslint-disable unicorn/no-null */
import React, { useMemo, useState } from 'react';
import { Box, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import { InNetworkAdBoardInviteDto } from '@keyops-hcp/dtos';

import i18next from '../../../../languages/i18n.config';
import { useStyles } from './styles';
import { useAdBoardById } from '../../../../hooks';
import { Loader } from '../../../Loader';
import { KeyopsHeader2, KeyopsSubTitle1 } from '../../../Headers';
import { ModerationActionCenterTable } from '../../../ModerationActionCenterTable';

import { AdBoardObjectivesAndActivities } from './components/AdBoardObjectivesAndActivities';

const generalTabs = [
  {
    value: 'objectives',
    label: i18next.t('ad_board.landing_page.objectives'),
  },
  {
    value: 'activities',
    label: i18next.t('ad_board.landing_page.activities'),
  },
];

const moderatorAfterAcceptanceTabs = [
  {
    value: 'moderation',
    label: i18next.t('ad_board.landing_page.moderation'),
  },
  {
    value: 'overview',
    label: i18next.t('ad_board.landing_page.overview'),
  },
];

interface AdBoardLandingPageTabsProperties {
  adBoardInvite: InNetworkAdBoardInviteDto;
  adBoardId: string;
}

export const AdBoardLandingPageTabs: React.FC<
  AdBoardLandingPageTabsProperties
> = ({ adBoardInvite, adBoardId }) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { isLoading, isError, data: adBoard } = useAdBoardById(adBoardId);
  const isModerator = adBoardInvite?.role === 'moderator';

  const [view, setView] = useState(
    isModerator && adBoardInvite.state === 'confirmed'
      ? 'moderation'
      : 'objectives',
  );

  const tabs = useMemo(
    () =>
      isModerator && adBoardInvite.state === 'confirmed'
        ? moderatorAfterAcceptanceTabs
        : generalTabs,
    [isModerator, adBoardInvite.state],
  );

  const handleTabChange = (
    event: React.MouseEvent<HTMLElement>,
    nextView: string,
  ) => {
    setView(nextView);
    if (!isModerator || adBoardInvite.state !== 'confirmed') {
      document
        .querySelector('#adboards-landing-page-tabs')
        ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  if (isError) return <div>{i18next.t('common.error')}</div>;

  return (
    <Box px={{ xs: 2, md: 7 }} py={{ xs: 1, md: 3 }}>
      {!isMobile && (
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            id={'adboards-landing-page-tabs'}
            value={view}
            onChange={handleTabChange}
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
                aria-label={tab.label}
                sx={{
                  ...styles.subTitle,
                  color:
                    view === tab.value
                      ? theme.palette.keyops.blue.keyopsBlue
                      : theme.palette.keyops.black.main,
                  fontSize: '0.875rem',
                  pb: 1,
                }}
              />
            ))}
          </Tabs>
        </Box>
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <Box sx={{ mt: 3 }}>
          {view === 'moderation' ? (
            <>
              <KeyopsHeader2>
                {i18next.t('ad_board.landing_page.moderation_tab.title')}
              </KeyopsHeader2>
              <KeyopsSubTitle1 mt={1}>
                {i18next.t('ad_board.landing_page.moderation_tab.subTitle')}
              </KeyopsSubTitle1>
              <ModerationActionCenterTable adBoard={adBoard} />
            </>
          ) : (
            <AdBoardObjectivesAndActivities adBoard={adBoard} />
          )}
        </Box>
      )}
    </Box>
  );
};
