/* eslint-disable unicorn/filename-case */
import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Close = (properties: SvgIconProps) => (
  <SvgIcon {...properties}>
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.19037 4.19037C4.49953 3.8812 5.00079 3.8812 5.30996 4.19037L14.81 13.6904C15.1191 13.9995 15.1191 14.5008 14.81 14.81C14.5008 15.1191 13.9995 15.1191 13.6904 14.81L4.19037 5.30996C3.8812 5.00079 3.8812 4.49953 4.19037 4.19037Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8101 4.19037C14.501 3.8812 13.9997 3.8812 13.6905 4.19037L4.19053 13.6904C3.88137 13.9995 3.88137 14.5008 4.19053 14.81C4.4997 15.1191 5.00095 15.1191 5.31012 14.81L14.8101 5.30996C15.1193 5.00079 15.1193 4.49953 14.8101 4.19037Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);
