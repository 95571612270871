import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import {
  Loading,
  ReferenceField,
  useEditContext,
  useGetOne,
  useList,
} from 'react-admin';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Pagination,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { ArrowBackIosNewRounded } from '@mui/icons-material';
import { KeyopsSubTitle1, KeyopsSubTitle2 } from '@keyops-hcp/ui-components';
import { dateFormatter } from '@keyops-hcp/ui-components/dist/utils/datetime';

import FeedFilters from './FeedFilters';
import FeedList from './FeedList';
import useProgressFeed from '../../../../custom-hooks/useProgressFeed';
import useParticipantsByAdBoardId from '../../../../custom-hooks/useParticipantsByAdBoardId';

const ITEMS_PER_PAGE = 10;

const stringAvatar = (name: string) => ({
  children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
});

const getTimeToComplete = (completedOn: Date, startedOn: Date): string => {
  if (!startedOn || !completedOn) return '-';
  const timeDiff = Math.abs(completedOn.getTime() - startedOn.getTime());
  const hours = Math.floor(timeDiff / (1000 * 60 * 60));
  const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
  return `${hours}:${minutes}`;
};

const IndividualFeed = ({
  userId,
  handleParticipantFeed,
}: {
  userId: number;
  handleParticipantFeed: (value: number | null) => void;
}) => {
  const { data: user } = useGetOne('Users', { id: userId });
  const { record: adBoardData } = useEditContext();

  const [filters, setFilters] = useState<{ [key: string]: string | number }>({
    userId,
  });

  const handleSearchChange = useCallback(
    (
      event:
        | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<string | number>,
      field: string,
    ) => {
      const { value } = event.target;
      const stringValue = value?.toString().trim();

      setFilters((previous) => {
        if (!stringValue && Number.parseInt(stringValue) !== 0) {
          // Remove the field from the filters if value is undefined, null, or empty
          // eslint-disable-next-line @typescript-eslint/no-unused-vars, sonarjs/no-unused-vars
          const { [field]: _, ...updatedFilters } = previous;
          return updatedFilters;
        }
        const updatedFilters = { ...previous };
        if (field === 'sectionGroupId') {
          delete updatedFilters['sectionId'];
        }
        return { ...updatedFilters, [field]: value };
      });
    },
    [],
  );

  const { data: participantsData = [] } = useParticipantsByAdBoardId(
    adBoardData?.id,
  );
  const { data = [], isLoading: isLoadingFeed } = useProgressFeed();

  const {
    data: listData,
    total: totalData,
    page,
    setPage,
  } = useList({
    data,
    filter: filters,
    perPage: ITEMS_PER_PAGE,
  });

  const currentParticipantData = useMemo(
    () => participantsData.find((participant) => participant.userId === userId),
    [participantsData, userId],
  );

  const [currentSectionIndex, currentSectionGroupIndex] = useMemo(() => {
    const latestSectionId =
      currentParticipantData?.progress?.progressDetails?.sectionProgress?.find(
        (section) => !section.finished,
      )?.sectionId;

    const currentSection =
      adBoardData?.sections?.find((section) => section.id === latestSectionId)
        ?.index + 1 || 0;
    const currentSectionGroup =
      adBoardData?.sectionGroups?.find((group) =>
        group.sections.some((section) => section.id === latestSectionId),
      )?.index + 1 || 0;

    return [currentSection, currentSectionGroup];
  }, [currentParticipantData, adBoardData]);

  const individualStats = useMemo(() => {
    const { startedOn, finishedOn } = currentParticipantData?.progress || {};

    return [
      {
        label: 'Started on',
        value: startedOn ? dateFormatter(startedOn.toString()) : '-',
      },
      {
        label: 'Completed on',
        value: finishedOn ? dateFormatter(finishedOn.toString()) : '-',
      },
      {
        label: 'Completion rate',
        value: `${currentParticipantData?.completionPercentage ?? '0'}%`,
      },
      {
        label: 'Time to complete',
        value:
          startedOn && finishedOn
            ? getTimeToComplete(finishedOn, startedOn)
            : '-',
      },
    ];
  }, [currentParticipantData]);

  const totalPages = useMemo(
    () => Math.ceil(totalData / ITEMS_PER_PAGE),
    [totalData],
  );

  return (
    <Box width="100%">
      <Button
        startIcon={<ArrowBackIosNewRounded sx={{ fontSize: 20 }} />}
        onClick={() => handleParticipantFeed(null)}
      >
        Back
      </Button>

      <Container maxWidth="md" sx={{ mt: 3 }}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Avatar
            {...stringAvatar(`${user?.firstName} ${user?.lastName}`)}
            sx={{
              width: 56,
              height: 56,
              backgroundColor: '#3376D1',
              color: '#FFFFFF',
            }}
          />
          <Box>
            <KeyopsSubTitle1 color="black">
              {user?.firstName} {user?.lastName}
              <ReferenceField
                source="user_id"
                reference="users"
                label="Author"
              />
            </KeyopsSubTitle1>
            <KeyopsSubTitle2>{user?.specialty[0]}</KeyopsSubTitle2>
          </Box>
        </Stack>

        <Box mt={3}>
          <FeedFilters filters={filters} onSearchChange={handleSearchChange} />
        </Box>

        <Card variant="outlined" sx={{ mt: 3, borderRadius: 2 }}>
          <CardContent>
            <Box display="flex" alignItems="center" width="100%">
              <Stack gap={1}>
                <KeyopsSubTitle2>Currently on</KeyopsSubTitle2>
                <Typography variant="body1">
                  Section {currentSectionGroupIndex} | Activity{' '}
                  {currentSectionIndex}
                </Typography>
              </Stack>
              <Divider orientation="vertical" sx={{ mx: 3 }} flexItem />
              <Box display="flex" flexGrow={1} justifyContent="space-between">
                {individualStats.map(({ label, value }, index) => (
                  <Stack key={index} gap={1}>
                    <KeyopsSubTitle2>{label}</KeyopsSubTitle2>
                    <Typography variant="body1">
                      {isLoadingFeed ? <Loading /> : value}
                    </Typography>
                  </Stack>
                ))}
              </Box>
            </Box>
          </CardContent>
        </Card>

        <Box mt={2}>
          {isLoadingFeed ? <Loading /> : <FeedList data={listData ?? []} />}
        </Box>

        <Pagination
          variant="outlined"
          shape="rounded"
          count={totalPages}
          page={page}
          onChange={(event, value) => setPage(value)}
          sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}
        />
      </Container>
    </Box>
  );
};

export default IndividualFeed;
