import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ModerationFlagsDto } from '@keyops-hcp/dtos';
import { getAdBoardModerationFlags } from '../api/ad-boards';

export const useGetAdBoardModerationFlags = (
  adBoardId: string,
): UseQueryResult<ModerationFlagsDto[], Error> => {
  return useQuery({
    queryKey: ['getAdBoardModerationFlags', adBoardId],
    queryFn: () => getAdBoardModerationFlags(adBoardId),
    enabled: !!adBoardId,
  });
};
