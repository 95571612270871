import DOMPurify from 'dompurify';
import React from 'react';
import { HTMLOutputProperties } from './html-output.types';

export const sanitize = (
  htmlContent: string,
  allowWholeDocument: boolean = false,
) => ({
  __html: DOMPurify.sanitize(htmlContent, {
    ADD_ATTR: ['class'],
    WHOLE_DOCUMENT: allowWholeDocument,
  }),
});

export const HTMLOutput: React.FC<HTMLOutputProperties> = ({
  html,
  allowWholeDocument = false,
}) => {
  return <div dangerouslySetInnerHTML={sanitize(html, allowWholeDocument)} />;
};
