import React from 'react';
import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';

import { theme } from '../../theme';

// eslint-disable-next-line unicorn/prevent-abbreviations
export const PrimaryTooltip = ({ children, ...props }: TooltipProps) => {
  return (
    <Tooltip
      placement={props.placement ?? 'bottom'}
      arrow={props.arrow ?? true}
      PopperProps={{
        modifiers: [
          ...(props?.slotProps?.popper?.modifiers ?? [
            {
              name: 'offset',
              options: {
                offset: [0, -4],
              },
            },
          ]), // Ensure modifiers are merged properly (useful to handle distance from anchor)
        ],
        sx: {
          [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.keyops.blue.lightSteelBlue, // Set arrow color
          },
          [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.keyops.blue.lightSteelBlue, // Tooltip background color
            color: 'white', // Tooltip text color
            fontWeight: 400,
            fontSize: '0.875rem',
            textAlign: 'center',
          },
          ...props?.slotProps?.popper?.sx, // Merge custom sx if provided
        },
      }}
      {...props}
    >
      {children}
    </Tooltip>
  );
};
