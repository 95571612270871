/* eslint-disable unicorn/filename-case */
import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Check = (properties: SvgIconProps) => (
  <SvgIcon {...properties}>
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6585 0.247437C16.0741 0.611119 16.1162 1.24288 15.7526 1.65852L7.00256 11.6585C6.64022 12.0726 6.01142 12.1162 5.59543 11.756L0.345429 7.21057C-0.0721057 6.84907 -0.117529 6.21754 0.243972 5.8C0.605474 5.38247 1.23701 5.33704 1.65454 5.69855L6.15248 9.59286L14.2474 0.341509C14.6111 -0.0741276 15.2429 -0.116245 15.6585 0.247437Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);
