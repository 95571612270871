import React, { useEffect, useRef, useState } from 'react';
import {
  AppBar,
  Stack,
  Toolbar,
  Typography,
  SxProps,
  alpha,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { AdBoardInviteDto, InNetworkAdBoardInviteDto } from '@keyops-hcp/dtos';

import i18next from '../../../../languages/i18n.config';
import { AdBoardInviteChoiceBox } from '../AdBoardInviteChoiceBox';
import { AdBoardUpcomingBox } from '../AdBoardUpcomingBox';
import { KeyopsHeader1 } from '../../../Headers';
import { EngagementDetailsBox } from '../../../EngagementDetailsBox';
import { EngagementTag } from '../../../EngagementTag';
import { theme } from '../../../../theme';
import { PrimaryButton } from '../../../PrimaryButton';
import { isEligibleForRewards } from '../../../../utils';

const headerContainer: SxProps = {
  alignItems: 'flex-start',
  backgroundColor: alpha(theme.palette.keyops.blue.arcticBlue, 0.2),
};
interface AdBoardLandingHeaderProperties {
  adBoardInvite: InNetworkAdBoardInviteDto;
  setOpenContractModal: React.Dispatch<React.SetStateAction<boolean>>;
  mobileBottomBar?: boolean;
}

interface AdBoardDetailsBoxProperties {
  adBoardInvite: InNetworkAdBoardInviteDto;
}
const AdBoardDetailsBox: React.FC<AdBoardDetailsBoxProperties> = ({
  adBoardInvite,
}) => {
  const totalEstimatedTime = adBoardInvite.adBoard.sectionGroups.reduce(
    (accumulator, sectionGroup) => {
      return accumulator + sectionGroup?.estimatedTime;
    },
    0,
  );

  return (
    <EngagementDetailsBox
      honorarium={adBoardInvite.honorarium}
      points={
        isEligibleForRewards(adBoardInvite?.invitee?.user?.hcpType)
          ? adBoardInvite.rewards
          : 0
      }
      startDate={adBoardInvite.adBoard.startDate}
      endDate={adBoardInvite.adBoard.endDate}
      duration={totalEstimatedTime}
      role={adBoardInvite.role}
    />
  );
};

const CTABox: React.FC<AdBoardLandingHeaderProperties> = ({
  adBoardInvite,
  setOpenContractModal,
  mobileBottomBar = false,
}: {
  adBoardInvite: AdBoardInviteDto;
  setOpenContractModal: React.Dispatch<React.SetStateAction<boolean>>;
  mobileBottomBar?: boolean;
}) => {
  const { t } = i18next;
  const navigate = useNavigate();
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const joinAdBoard = () => {
    navigate(`/adboard/${adBoardInvite.adBoardId}/sections`);
  };

  //Different calls to action depending on adboard and invite states, and invite type
  if (adBoardInvite.state === 'sent') {
    return (
      <AdBoardInviteChoiceBox
        adBoardId={adBoardInvite.adBoardId}
        startDate={adBoardInvite.adBoard.startDate}
        role={adBoardInvite.role}
        setOpenContractModal={setOpenContractModal}
        mobileBottomBar={mobileBottomBar}
      />
    );
  }

  if (
    adBoardInvite.state === 'confirmed' ||
    adBoardInvite.state === 'contract-signed' ||
    adBoardInvite.state === 'failed-contract-confirmation'
  ) {
    if (adBoardInvite.adBoard.state === 'live') {
      return (
        <PrimaryButton
          width={isMobile ? '100%' : '184px'}
          height="56px"
          onClick={joinAdBoard}
          minWidth="none"
        >
          {t('ad_board.landing_page.cta.open.join')}
        </PrimaryButton>
      );
    }
    // NOTE: add components for adBoard.state === 'closed' when better understanding of closing states

    return (
      <AdBoardUpcomingBox
        startDate={adBoardInvite.adBoard.startDate}
        role={adBoardInvite.role}
      />
    );
  }

  return <></>;
};

export const AdBoardLandingHeader: React.FC<AdBoardLandingHeaderProperties> = ({
  adBoardInvite,
  setOpenContractModal,
}) => {
  const { breakpoints, palette } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  const ctaReference = useRef(null);
  const [showMobileCTABar, setShowMobileCTABar] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setShowMobileCTABar(!entry.isIntersecting);
      },
      { threshold: 0 }, // Trigger when the element is fully out of the viewport
    );

    const target = ctaReference.current;
    if (target) observer.observe(target);

    return () => {
      if (target) observer.unobserve(target);
    };
  }, []);

  return (
    <Stack
      direction={'column'}
      sx={headerContainer}
      spacing={3}
      px={{ xs: 2, md: 7 }}
      py={{ xs: 3, md: 2 }}
    >
      {/* Kolab tag */}
      <EngagementTag engagementType="kolab" />

      <Stack
        direction={{ xs: 'column', sm: 'column', md: 'row' }}
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        spacing={3}
      >
        <Stack direction={'column'} spacing={4}>
          {/* Intro */}
          <Stack direction={'column'} spacing={2}>
            <KeyopsHeader1>{adBoardInvite.adBoard.title}</KeyopsHeader1>

            <Typography variant="body1">
              {i18next.t(
                'ad_board.landing_page.company_invite.' + adBoardInvite.role,
                {
                  name: adBoardInvite.adBoard.company?.name,
                },
              )}
            </Typography>
          </Stack>
          <div ref={ctaReference}>
            <CTABox
              adBoardInvite={adBoardInvite}
              setOpenContractModal={setOpenContractModal}
            />
          </div>
        </Stack>
        {isMobile && showMobileCTABar && (
          <AppBar
            position="fixed"
            elevation={0}
            sx={{
              top: 'auto',
              bottom: 0,
              minHeight: '96px',
              backgroundColor: 'white',
              border: `1px solid ${palette.keyops.blue.lightSteelBlue20}`,
              py: 2,
            }}
          >
            <Toolbar>
              <CTABox
                adBoardInvite={adBoardInvite}
                setOpenContractModal={setOpenContractModal}
                mobileBottomBar
              />
            </Toolbar>
          </AppBar>
        )}
        {/* Top right details box */}
        <AdBoardDetailsBox adBoardInvite={adBoardInvite} />
      </Stack>
    </Stack>
  );
};
