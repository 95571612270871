/* eslint-disable unicorn/filename-case */
import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const FlagSolid = (properties: SvgIconProps) => (
  <SvgIcon {...properties}>
    <svg
      width="13"
      height="15"
      viewBox="0 0 13 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_118_773)">
        <path
          d="M1.85714 0.9375C1.85714 0.418945 1.44219 0 0.928571 0C0.414955 0 0 0.418945 0 0.9375V1.875V10.7812V14.0625C0 14.5811 0.414955 15 0.928571 15C1.44219 15 1.85714 14.5811 1.85714 14.0625V10.3125L3.72299 9.84082C4.91563 9.53906 6.1779 9.67969 7.27768 10.2334C8.56027 10.8809 10.0489 10.96 11.3895 10.4502L12.3964 10.0693C12.7592 9.93164 13 9.58301 13 9.19043V1.93359C13 1.25977 12.2978 0.820312 11.7 1.12207L11.4214 1.2627C10.0779 1.94238 8.49643 1.94238 7.1529 1.2627C6.13438 0.74707 4.96496 0.618164 3.85938 0.896484L1.85714 1.40625V0.9375Z"
          fill="#C53030"
        />
      </g>
      <defs>
        <clipPath id="clip0_118_773">
          <rect width="13" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);
