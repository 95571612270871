import {
  DiscussionMessageContextDto,
  MessageDto,
  VideoMessageContextDto,
} from '@keyops-hcp/dtos';
import { axiosCall } from '../utils/api';
import { DISCUSSIONS_ENDPOINT } from '../utils/api-routes';
import { DiscussionMessageType } from '@keyops-hcp/constants';

export const createOneDiscussionMessage = async (
  content: string,
  discussionId: string,
  threadId: string,
  type: DiscussionMessageType,
  context: DiscussionMessageContextDto | VideoMessageContextDto,
): Promise<MessageDto> => {
  try {
    const response = await axiosCall(
      false,
      'post',
      `${DISCUSSIONS_ENDPOINT}/${discussionId}/messages`,
      undefined,
      undefined,
      { content, threadId, type, context },
    );

    return response.data.data;
  } catch (error) {
    console.error('Error creating one discussion message', error);
    throw error;
  }
};

/**
 * Updates DiscussionMessage hidden field
 * @param discussionId
 * @param messageId
 * @param newHiddenValue
 * @returns
 */
export const toggleDiscussionMessageVisibility = async (
  discussionId: string,
  messageId: string,
  newHiddenValue: boolean,
): Promise<void> => {
  try {
    const response = await axiosCall(
      false,
      'post',
      `${DISCUSSIONS_ENDPOINT}/${discussionId}/messages/${messageId}/hide`,
      undefined,
      undefined,
      {
        newHiddenValue,
      },
    );
    return response.data.data;
  } catch (error) {
    console.error('Error hiding/unhiding one discussion message', error);
    throw error;
  }
};
