import { useMutation } from '@tanstack/react-query';
import {
  AdBoardDiscussionSectionProgressCombined,
  DiscussionMessageContextDto,
  DocumentMarkupMessageContextDto,
  MessageDto,
  VideoMessageContextDto,
} from '@keyops-hcp/dtos';

import { createOneDiscussionMessage } from '../api/discussion-messages';
import { queryClient } from '../wrappers';
import { useAdBoardContext } from '../context';
import { analytics } from '../utils/analytics';

interface BaseMessageParameters {
  message: string;
  discussionId: string;
  threadId: string;
  setSelectedMarkupMessageId?: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}

// Define interfaces for each specific case
interface VideoMessageParameters extends BaseMessageParameters {
  type: 'video';
  context: VideoMessageContextDto;
}

interface DiscussionMessageParameters extends BaseMessageParameters {
  type: 'discussion';
  context: DiscussionMessageContextDto;
}

interface DocumentMarkupMessageParameters extends BaseMessageParameters {
  type: 'documentMarkup';
  context: DocumentMarkupMessageContextDto;
}

// Union type for the mutation parameters
type PostDiscussionMessageParameters =
  | VideoMessageParameters
  | DiscussionMessageParameters
  | DocumentMarkupMessageParameters;

/**
 * @param {string} adBoardId - The id of the current Ad Board
 * @param {string} currentSectionId - The id of the current section
 * @returns
 */
export const usePostDiscussionMessage = () => {
  const { currentSectionProgress, updateProgress } = useAdBoardContext();

  return useMutation<MessageDto, unknown, PostDiscussionMessageParameters>(
    async ({
      message,
      discussionId,
      threadId,
      type,
      context,
    }): Promise<MessageDto> => {
      const createdMessage = await createOneDiscussionMessage(
        message,
        discussionId,
        threadId,
        type,
        context,
      );

      return createdMessage;
    },
    {
      onSuccess: async (createdMessage, parameters) => {
        if (updateProgress && !currentSectionProgress?.finished) {
          // discussion section
          if (parameters.type === 'discussion') {
            const existingFinishedTopics =
              (
                currentSectionProgress as AdBoardDiscussionSectionProgressCombined
              )?.finishedTopics || [];
            if (!existingFinishedTopics.includes(createdMessage.discussionId)) {
              const finishedTopics = [
                ...existingFinishedTopics,
                createdMessage.discussionId,
              ];
              analytics.track(`AdBoard Progress Discussion`, {
                finishedTopics,
              });
              updateProgress({
                finishedTopics,
              });
            }
          }

          // video & markup section
          if (
            parameters.type === 'video' ||
            parameters.type === 'documentMarkup'
          ) {
            analytics.track(`AdBoard Progress discussion comment`);
            updateProgress({});
          }
        }

        // Refetch the discussions query
        await queryClient.invalidateQueries({
          queryKey: ['getOneDiscussion', parameters.discussionId],
        });

        // if documentMarkup, maintain focus on posted message
        if (
          parameters.type === 'documentMarkup' &&
          parameters.setSelectedMarkupMessageId
        )
          parameters.setSelectedMarkupMessageId(createdMessage.id);
      },
      onError: (error) => {
        console.error(error);
      },
    },
  );
};
