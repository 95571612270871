import { useMutation } from '@tanstack/react-query';

import { toggleDiscussionMessageVisibility } from '../api/discussion-messages';
import { queryClient } from '../wrappers';
import { analytics } from '../utils/analytics';

interface BaseMessageParameters {
  discussionId: string;
  messageId: string;
  newHiddenValue: boolean;
}

/**
 * @param {string} adBoardId - The id of the current Ad Board
 * @param {string} currentSectionId - The id of the current section
 * @returns
 */
export const useToggleDiscussionMessageVisibility = () => {
  return useMutation<
    { discussionId: string; messageId: string; newHiddenValue: boolean },
    unknown,
    BaseMessageParameters
  >(
    async ({
      discussionId,
      messageId,
      newHiddenValue,
    }): Promise<{
      discussionId: string;
      messageId: string;
      newHiddenValue: boolean;
    }> => {
      await toggleDiscussionMessageVisibility(
        discussionId,
        messageId,
        newHiddenValue,
      );
      return { discussionId, messageId, newHiddenValue };
    },
    {
      onSuccess: async (result, parameters) => {
        analytics.track(`AdBoard Discussion Message visibility toggled`, {
          discussionId: result.discussionId,
          messageId: result.messageId,
          newHiddenValue: result.newHiddenValue,
        });
        // Refetch the discussions query
        await queryClient.invalidateQueries({
          queryKey: ['getOneDiscussion', parameters.discussionId],
        });
      },
      onError: (error) => {
        console.error(error);
      },
    },
  );
};
