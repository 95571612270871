import { ModerationFlagsDto } from '@keyops-hcp/dtos';
import { t } from 'i18next';

import { axiosCall } from '../utils/api';
import { MODERATION_FLAGS_ENDPOINT } from '../utils/api-routes';

export const updateOneModerationFlags = async (
  id: string,
  updatedValue: Partial<ModerationFlagsDto>,
): Promise<ModerationFlagsDto[]> => {
  try {
    const response = await axiosCall(
      false,
      'patch',
      MODERATION_FLAGS_ENDPOINT + `/${id}`,
      undefined,
      undefined,
      updatedValue,
    );

    if (response.status === 200) {
      return response.data.data;
    }
    throw new Error(response?.data?.message || t('apiErrorMessages.not_found'));
  } catch (error) {
    console.error('Error calling updateOneModerationFlags:', error);
    throw new Error(
      error?.response?.data?.message || t('apiErrorMessages.not_found'),
    );
  }
};
