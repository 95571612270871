import React from 'react';
import { Box, Typography, Stack } from '@mui/material';

import { AdBoardParticipantRole } from '@keyops-hcp/constants';

import i18next from '../../../../languages/i18n.config';
import {
  useDeclineAdBoardInviteMutation,
  useAcceptAdBoardInviteMutation,
} from '../../../../hooks';
import { theme } from '../../../../theme';
import { PrimaryButton } from '../../../PrimaryButton';
import { TextButton } from '../../../TextButton';

export const AdBoardInviteChoiceBox = ({
  adBoardId,
  startDate,
  role,
  setOpenContractModal,
  mobileBottomBar,
}: {
  adBoardId: string;
  startDate?: string;
  role: AdBoardParticipantRole;
  setOpenContractModal: React.Dispatch<React.SetStateAction<boolean>>;
  mobileBottomBar: boolean;
}) => {
  const { t } = i18next;
  const acceptAdBoardInviteMutation = useAcceptAdBoardInviteMutation();
  const declineAdBoardInviteMutation = useDeclineAdBoardInviteMutation();

  const acceptInvite = () => {
    if (role === 'participant') {
      setOpenContractModal(true);
    } else {
      acceptAdBoardInviteMutation.mutate(adBoardId);
    }
  };

  const declineInvite = () => {
    declineAdBoardInviteMutation.mutate(adBoardId);
  };

  const RegisterButton = (
    <PrimaryButton
      onClick={acceptInvite}
      width={mobileBottomBar ? '100%' : 'fit-content'}
      minWidth={{ xs: '50%', md: '200px' }}
      height={{ xs: mobileBottomBar ? '64px' : '48px', md: '72px' }}
      data-testid="adboard-invite-accept-btn"
    >
      <Typography
        variant="h2"
        color={'inherit'}
        fontSize={{ md: '16px', xs: '14px' }}
        minWidth={{ xs: '50%', md: '200px' }}
      >
        {role === 'moderator'
          ? t('ad_board.landing_page.cta.invited.moderate')
          : t('ad_board.landing_page.cta.invited.register')}

        {startDate && (
          <Box fontSize={{ xs: '12px', md: '14px' }}>
            {t('ad_board.landing_page.starts', {
              date: new Date(startDate).toLocaleDateString(i18next.language, {
                month: 'short',
                day: '2-digit',
              }),
            })}
          </Box>
        )}
      </Typography>
    </PrimaryButton>
  );

  if (mobileBottomBar) return RegisterButton;

  return (
    <Stack direction="column" alignItems={'flex-start'}>
      <Stack direction="row" pb={2} spacing={{ xs: 3, md: 4 }} width={'100%'}>
        {RegisterButton}
        <TextButton
          onClick={declineInvite}
          data-testid="adboard-invite-decline-btn"
        >
          <Typography
            variant="h2"
            color={'inherit'}
            sx={{
              fontSize: { md: '16px', xs: '14px' },
            }}
          >
            {t('ad_board.landing_page.cta.invited.decline')}
          </Typography>
        </TextButton>
      </Stack>
      <Typography variant="body3" color={theme.palette.keyops.gray[600]}>
        {t('ad_board.landing_page.cta.invited.helperText.' + role)}
      </Typography>
    </Stack>
  );
};
