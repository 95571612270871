import React, { useState, useRef } from 'react';
import { HTMLOutput } from '@keyops-hcp/ui-components';
import 'react-quill/dist/quill.snow.css'; // Quill styles
import JoditEditor from 'jodit-react';

interface RichTextEditorProperties {
  value?: string;
  onChange: React.Dispatch<React.SetStateAction<string>>;
}

export const RichTextEditor: React.FC<RichTextEditorProperties> = ({
  value = '',
  onChange,
}) => {
  const [content, setContent] = useState(value || '');
  const [isSanitizedView, setIsSanitizedView] = useState(false);
  const editor = useRef(null);

  // Handle file upload
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'text/html') {
      try {
        const text = await file.text(); // Read file content
        setContent(text);
      } catch (error) {
        console.error('Failed to read file:', error);
        alert('There was an error reading the file.');
      }
    } else {
      alert('Please upload a valid HTML file.');
    }
  };

  const handleChange = (newContent) => {
    setContent(newContent);
    if (onChange) onChange(newContent);
  };

  // Jodit configuration
  const config = {
    readonly: false,
    toolbarSticky: true,
    toolbarAdaptive: false,
    height: 500,
    spellcheck: true,
    language: 'en',
    allowResizeX: false,
    allowResizeY: true,
    buttons: [
      'source',
      'fullsize',
      'print',
      'about',
      '|',
      'undo',
      'redo',
      '|',
      'bold',
      'italic',
      'underline',
      'strikethrough',
      '|',
      'superscript',
      'subscript',
      '|',
      'ul',
      'ol',
      'outdent',
      'indent',
      '|',
      'fontsize',
      'brush',
      'paragraph',
      '|',
      'image',
      'video',
      'file',
      'table',
      'link',
      '|',
      'align',
      'hr',
      'eraser',
      'copyformat',
      '|',
      'symbol',
      'preview',
      'code',
    ],
    uploader: {
      insertImageAsBase64URI: true,
    },
    filebrowser: {
      ajax: {
        url: '/your-file-upload-url',
      },
    },
    showCharsCounter: true,
    showWordsCounter: true,
    showXPathInStatusbar: false,
  };

  return (
    <div>
      {/* Upload HTML File */}
      <div style={{ marginBottom: '10px' }}>
        <label htmlFor="upload-html" style={{ marginRight: '10px' }}>
          Upload HTML File:
        </label>
        <input
          type="file"
          id="upload-html"
          accept=".html"
          onChange={handleFileUpload}
        />
      </div>

      {/* Toggle between raw and sanitized views */}
      <div style={{ marginBottom: '10px' }}>
        <label>
          <input
            type="checkbox"
            checked={isSanitizedView}
            onChange={() => setIsSanitizedView(!isSanitizedView)}
          />
          View Sanitized Content
        </label>
      </div>

      {/* Show either the editable editor or a sanitized preview */}
      {isSanitizedView ? (
        <div
          style={{
            border: '1px solid #ccc',
            padding: '10px',
            minHeight: '200px',
            backgroundColor: '#f9f9f9',
          }}
        >
          <HTMLOutput html={content} allowWholeDocument={true} />
        </div>
      ) : (
        <JoditEditor
          ref={editor}
          value={content}
          config={config}
          onBlur={(newContent) => handleChange(newContent)}
        />
      )}
    </div>
  );
};
