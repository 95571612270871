import React, { ChangeEvent, useEffect, useState } from 'react';
import { useEditContext } from 'react-admin';
import {
  Box,
  FormControl,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { AdBoardDto } from '@keyops-hcp/dtos';

interface FilterSelectProperties {
  id: string;
  field: string;
  value: string | number;
  label: string;
  items: { id: string; index: number; title: string }[];
  onChange: (
    event:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string | number>,
    field: string,
  ) => void;
}

const FilterSelect = ({
  id,
  field,
  value,
  label,
  items,
  onChange,
}: FilterSelectProperties) => (
  <Box width={200} display={'inline-block'}>
    <FormControl fullWidth>
      <Select
        id={id}
        size="small"
        value={value}
        displayEmpty
        renderValue={(selected) =>
          items.find((item) => item.id === selected)?.title ?? (
            <Typography variant="body1" color="GrayText">
              {label}
            </Typography>
          )
        }
        onChange={(event) => onChange(event, field)}
      >
        <MenuItem>
          <Typography variant="body1" color="GrayText">
            <em>{label}</em>
          </Typography>
        </MenuItem>
        {items.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            <Typography
              sx={{
                maxWidth: '35ch',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {item.title}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Box>
);

interface FeedFiltersProperties {
  filters: { [key: string]: string | number };
  onSearchChange: (
    event:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string | number>,
    field: string,
  ) => void;
}

const FeedFilters = ({ filters, onSearchChange }: FeedFiltersProperties) => {
  const { record: adBoardData } = useEditContext<AdBoardDto>();
  const [sectionOptions, setSectionOptions] = useState(
    adBoardData?.sections ?? [],
  );

  useEffect(() => {
    if (filters?.sectionGroupId) {
      setSectionOptions(
        adBoardData?.sections?.filter(
          (section) => section.sectionGroupId === filters?.sectionGroupId,
        ),
      );
    }
  }, [filters?.sectionGroupId]);
  return (
    <Box width={'100%'} display={'flex'} gap={2.5}>
      <FormControl sx={{ width: '35ch' }} variant="outlined">
        <OutlinedInput
          id="search-feed"
          placeholder="Search"
          size="small"
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          onChange={(event) => onSearchChange(event, 'q')}
        />
      </FormControl>

      <FilterSelect
        id="section-simple-select"
        field={'sectionGroupId'}
        value={filters?.sectionGroupId}
        label="Section"
        items={adBoardData?.sectionGroups ?? []}
        onChange={onSearchChange}
      />
      <FilterSelect
        id="activity-simple-select"
        field={'sectionId'}
        value={filters?.sectionId}
        label="Activity"
        items={sectionOptions}
        onChange={onSearchChange}
      />
    </Box>
  );
};

export default FeedFilters;
