/* eslint-disable unicorn/filename-case */
/* eslint-disable unicorn/no-null */
import React, { useMemo } from 'react';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { AdBoardDto, ModerationFlagsDto } from '@keyops-hcp/dtos';
import { useNavigate } from 'react-router-dom';

import { useGetAdBoardModerationFlags } from '../../hooks';
import {
  ActionCenterTableData,
  useActionCenterTableColumns,
} from './useActionCenterTableColumns';
import { Loader } from '../Loader';
import { KeyopsSubTitle1 } from '../Headers';
import { hoverItemStyle } from '../../styles';
import i18next from '../../languages/i18n.config';

type ModerationActionCenterTableProperties = {
  adBoard: AdBoardDto;
  toggleActionCenterDrawer?: (newOpen: boolean) => void;
};

export const ModerationActionCenterTable: React.FC<
  ModerationActionCenterTableProperties
> = ({ adBoard, toggleActionCenterDrawer }) => {
  const {
    data: moderationFlags,
    error: moderationFlagsError,
    isLoading: isModerationFlagsLoading,
  } = useGetAdBoardModerationFlags(adBoard.id);

  const tableData = useMemo(() => {
    const sectionMap = new Map(adBoard?.sections?.map((s) => [s.id, s]));
    const sectionGroupMap = new Map(
      adBoard?.sectionGroups?.map((g) => [g.id, g]),
    );
    return (
      moderationFlags
        ?.filter((moderationFlag) => moderationFlag.status === 'new') // Only display "new" ModerationFlags
        ?.map((rec) => {
          const section = sectionMap.get(rec.adBoardSectionId);
          const sectionGroup = sectionGroupMap.get(section?.sectionGroupId);

          return {
            ...rec,
            section: sectionGroup
              ? `Sec. ${sectionGroup.index + 1} | Act. ${section?.index + 1}`
              : 'General',
          };
        }) || []
    );
  }, [moderationFlags, adBoard]);

  const columns = useActionCenterTableColumns();
  const table = useReactTable<ActionCenterTableData>({
    data: tableData as unknown as ActionCenterTableData[],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnVisibility: {
        sectionId: false,
      },
    },
  });

  const navigate = useNavigate();
  // Given a ModerationFlags, route to a section or a discussionMessage
  const handleNavigate = (moderationFlag: ModerationFlagsDto) => {
    if (moderationFlag.targetPath) {
      const parsedUrl = new URL(moderationFlag.targetPath);
      navigate(parsedUrl.pathname + parsedUrl.search + parsedUrl.hash);
    }
  };

  if (isModerationFlagsLoading) return <Loader />;
  if (moderationFlagsError) return <div>{i18next.t('common.error')}</div>;

  return (
    <>
      <TableContainer
        component={Card}
        variant="outlined"
        sx={{ maxHeight: '100%', borderRadius: 3, mt: 2 }}
      >
        <Table
          sx={{ minWidth: 650 }}
          aria-label="action center table"
          data-testid="action-center-table"
        >
          <TableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableCell
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    sx={{
                      cursor: 'pointer',
                      whiteSpace: 'pre-wrap',
                      fontWeight: 500,
                    }}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel().rows.length > 0 ? (
              table.getRowModel().rows.map((row) => {
                return (
                  <TableRow
                    key={row.id}
                    onClick={() => {
                      if (
                        toggleActionCenterDrawer &&
                        row.original.adBoardSectionId
                      )
                        toggleActionCenterDrawer(false);
                      handleNavigate(row.original);
                    }} // Navigate either to section or discussionMessageId
                    sx={{
                      ...(row.original.targetPath ? hoverItemStyle() : {}),
                    }}
                  >
                    {row.getVisibleCells().map((cell) => {
                      let minWidth = 'auto';
                      if (cell.column.id === 'section') minWidth = '90px';
                      if (cell.column.id === 'createdAt') minWidth = '50px';
                      return (
                        <TableCell
                          key={cell.id}
                          sx={{
                            minWidth,
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  align="center"
                  sx={{ py: 4 }}
                >
                  {i18next.t(
                    'ad_board.moderator_recommendations_table.empty_state',
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <KeyopsSubTitle1 sx={{ mt: 1, justifySelf: 'flex-end' }}>
        {i18next.t('common.poweredByAI')}
      </KeyopsSubTitle1>
    </>
  );
};
