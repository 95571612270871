import React from 'react';
import {
  Box,
  Divider,
  IconButton,
  Link,
  Skeleton,
  useTheme,
} from '@mui/material';
import { FiRadio } from 'react-icons/fi';

import { useAdBoardContext } from '../../context';
import i18next from '../../languages/i18n.config';
import { KeyopsHeader2, KeyopsSubTitle2 } from '../Headers';
import { formatDateWithSuffix } from '../../utils/datetime';
import { ADBOARD_LANDING_ROOT } from '../../utils/physician-ui-routes';
import { COMPONENT_FEATURE_FLAGS } from '../../utils/component-feature-flags';
import { Attendees } from '../Attendees';
import { Notifications } from '../Notifications';
import { CalendarCheck, MessageBubble, TaskList } from '../../icons';
import { PrimaryTooltip } from '../PrimaryTooltip';
import { hoverItemStyle } from '../../styles';

interface DesktopAdBoardTopBarProperties {
  toggleDiscussionDrawer: (isDrawerOpen: boolean) => void;
  toggleSectionDrawer: () => void;
  toggleActionCenterDrawer: (newOpen: boolean) => void;
  isSectionDrawerOpen: boolean;
  isDiscussionDrawerOpen: boolean;
  isActionCenterOpen: boolean;
}

export const DesktopAdBoardTopBar = ({
  toggleDiscussionDrawer,
  toggleSectionDrawer,
  toggleActionCenterDrawer,
  isSectionDrawerOpen,
  isDiscussionDrawerOpen,
  isActionCenterOpen,
}: DesktopAdBoardTopBarProperties): React.JSX.Element => {
  const { palette } = useTheme();

  const { isLoading, isError, role, adBoardData, shouldShowMainHcpNav } =
    useAdBoardContext();

  if (isError) return <>Something went wrong</>;

  return (
    <>
      <Box flexGrow={1}>
        <KeyopsHeader2 mb={'8px'}>
          {isLoading || !adBoardData ? (
            <Skeleton data-testid="ad-board-title-skeleton" />
          ) : (
            adBoardData.title
          )}
        </KeyopsHeader2>
        <Box display={'flex'} gap={4}>
          <KeyopsSubTitle2 fontWeight={400}>
            {isLoading || !adBoardData ? (
              <Skeleton
                data-testid="ad-board-company-name-skeleton"
                sx={{ minWidth: '50px' }}
              />
            ) : (
              adBoardData.company.name
            )}
          </KeyopsSubTitle2>
          {adBoardData?.endDate && (
            <Box display={'flex'} alignItems={'center'} gap={1}>
              <FiRadio color={palette.keyops.teal} fontSize={20} />
              <KeyopsSubTitle2>
                {i18next.t('ad_board.adBoardTopBar.openUntil', {
                  endDate: formatDateWithSuffix(adBoardData?.endDate),
                })}
              </KeyopsSubTitle2>
            </Box>
          )}
          <Link
            data-testid="adboard-landing-link"
            href={`${ADBOARD_LANDING_ROOT}/${adBoardData?.id}`}
            underline="none"
          >
            <KeyopsSubTitle2 color={'primary'}>
              {i18next.t('ad_board.adBoardTopBar.viewDetails')}
            </KeyopsSubTitle2>
          </Link>
        </Box>
      </Box>
      <Box display={'flex'} flexDirection={'row'} gap={1}>
        <Attendees sx={{ mr: 2 }} />
        {COMPONENT_FEATURE_FLAGS.ADBOARD_DISCUSSION && (
          <PrimaryTooltip title={i18next.t('navbar.hover.chat')}>
            <IconButton
              data-testid="discussion-board-button"
              aria-label="discussion-board-button"
              onClick={() => toggleDiscussionDrawer(true)}
              sx={{
                ...hoverItemStyle(),
                color: isDiscussionDrawerOpen
                  ? palette.keyops.teal
                  : palette.keyops.blue.midnightBlue,
                px: 0.5,
              }}
              disableFocusRipple
              disableTouchRipple
              disableRipple
            >
              <MessageBubble
                sx={{
                  fontSize: '25px',
                }}
              />
            </IconButton>
          </PrimaryTooltip>
        )}
        <PrimaryTooltip title={i18next.t('navbar.hover.activities')}>
          <IconButton
            data-testid="section-drawer-toggle-button"
            aria-label="section-drawer-toggle-button"
            onClick={() => toggleSectionDrawer()}
            sx={{
              ...hoverItemStyle(),
              color: isSectionDrawerOpen
                ? palette.keyops.teal
                : palette.keyops.blue.midnightBlue,
              px: 0.5,
            }}
            disableFocusRipple
            disableTouchRipple
            disableRipple
          >
            <CalendarCheck
              sx={{
                fontSize: '25px',
              }}
            />
          </IconButton>
        </PrimaryTooltip>
        {shouldShowMainHcpNav() && <Notifications />}
        {role === 'moderator' && (
          <>
            <Divider orientation="vertical" flexItem />
            <PrimaryTooltip
              title={i18next.t('navbar.hover.moderator_recommendations')}
            >
              <IconButton
                data-testid="moderator-recommendations-toggle-button"
                aria-label="moderator-recommendations-toggle-button"
                onClick={() => toggleActionCenterDrawer(true)}
                sx={{
                  ...hoverItemStyle(),
                  color: isActionCenterOpen
                    ? palette.keyops.teal
                    : palette.keyops.blue.midnightBlue,
                  pl: 1,
                }}
                disableFocusRipple
                disableTouchRipple
                disableRipple
              >
                <TaskList
                  sx={{
                    fontSize: '23px',
                  }}
                />
              </IconButton>
            </PrimaryTooltip>
          </>
        )}
      </Box>
    </>
  );
};
