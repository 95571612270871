import React, { SyntheticEvent } from 'react';
import { alpha, SwipeableDrawer, useTheme } from '@mui/material';

export interface BottomNavBarDrawerProperties {
  contentType: string; // content of the drawer (notifications, profile, ...)
  isOpen: boolean;
  onClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  onOpen: (event: SyntheticEvent<{}, Event>) => void;
  children: React.ReactNode;
  minHeight?: number | string;
  maxHeight?: number | string;
}

/**
 * BottomNavBarDrawer is used in mobile to display the desktop top navbar content
 */
export const BottomNavBarDrawer = ({
  contentType,
  isOpen,
  onOpen,
  onClose,
  children,
  minHeight,
  maxHeight,
}: BottomNavBarDrawerProperties) => {
  const { palette } = useTheme();
  return (
    <SwipeableDrawer
      id={contentType + '-bottom-navbar-drawer'}
      data-testid={contentType + '-bottom-navbar-drawer'}
      anchor={'bottom'}
      open={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: '8px 8px 0 0',
          minHeight: minHeight ?? 'auto', // Only applies if minHeight is defined
          maxHeight: maxHeight ?? 'auto', // Only applies if maxHeight is defined
          display: 'flex',
          flexDirection: 'column',
          px: 1,
        },
      }}
      ModalProps={{
        slotProps: {
          backdrop: {
            sx: {
              backgroundColor: alpha(palette.keyops.black.main, 0.24),
            },
          },
        },
      }}
    >
      {children}
    </SwipeableDrawer>
  );
};
