import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Hide = (properties: SvgIconProps) => (
  <SvgIcon {...properties}>
    <svg
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.970124 0.129632C0.710128 -0.0785665 0.332635 -0.0303254 0.127638 0.233731C-0.0773581 0.497788 -0.0298589 0.881178 0.230136 1.08938L15.0299 12.8704C15.2899 13.0786 15.6674 13.0303 15.8724 12.7663C16.0774 12.5022 16.0299 12.1188 15.7699 11.9106L13.1399 9.81848C14.1299 8.78765 14.7999 7.6324 15.1374 6.8123C15.2199 6.61172 15.2199 6.38828 15.1374 6.1877C14.7649 5.28128 13.9824 3.96099 12.8124 2.85906C11.6374 1.74698 10.02 0.812625 8 0.812625C6.29503 0.812625 4.87505 1.48038 3.76757 2.35634L0.970124 0.129632ZM4.74506 3.13328C5.65004 2.48075 6.73752 2.03135 8 2.03135C9.62997 2.03135 10.9699 2.78289 11.9974 3.75026C12.9599 4.65922 13.6249 5.7383 13.9649 6.5C13.6499 7.21092 13.0499 8.19606 12.1924 9.06186L10.8474 7.9904C11.0749 7.54353 11.2024 7.03827 11.2024 6.5C11.2024 4.70492 9.76997 3.25007 8.0025 3.25007C7.19751 3.25007 6.46003 3.55221 5.89754 4.04986L4.74506 3.13328ZM9.87247 7.216L7.835 5.59357C7.94 5.37776 8 5.13148 8 4.87504C8 4.73539 7.9825 4.59828 7.95 4.46879C7.9675 4.46879 7.9825 4.46879 8 4.46879C9.10498 4.46879 9.99996 5.37776 9.99996 6.5C9.99996 6.75136 9.95497 6.99257 9.87247 7.216ZM10.1075 10.5243C9.46997 10.8011 8.76749 10.9687 8 10.9687C6.37003 10.9687 5.03005 10.2171 4.00257 9.24974C3.04009 8.34078 2.3751 7.2617 2.0351 6.5C2.2426 6.03282 2.5726 5.44631 3.02009 4.85472L2.0776 4.10064C1.50761 4.85472 1.10012 5.60627 0.862625 6.1877C0.780127 6.38828 0.780127 6.61172 0.862625 6.8123C1.23512 7.71872 2.01761 9.03901 3.18758 10.1409C4.36256 11.253 5.98004 12.1874 8 12.1874C9.19498 12.1874 10.2475 11.8598 11.1549 11.3622L10.1075 10.5243ZM4.80006 6.5C4.80006 8.29508 6.23253 9.74993 8 9.74993C8.33249 9.74993 8.65249 9.69915 8.95498 9.60267L7.55001 8.48042C6.96252 8.34332 6.47253 7.94216 6.20753 7.40643L4.80506 6.28418C4.80006 6.35528 4.79756 6.42637 4.79756 6.5H4.80006Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);
